import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import SubtitleWithButton from '../../../common/SubtitleWithButton';
import EmptyListOfItemsContainer from '../EmptyListOfItemsContainer';
import CustomModal from '../../../common/CustomModal';
import AddVehiclesModalContent from './AddVehiclesModalContent';
import VehiclesInformationComponent from './VehiclesInformationComponent';
import { toast } from 'react-toastify';

const AddVehiclesComponent = ({
  saleData,
  setSaleData,
  allVehiclesForNewSaleData,
  isLoading
}) => {
  const [modalConfig, setModalConfig] = useState({
    isModalOpen: false,
    modalTitle: 'Seleccionar vehículos'
  });
  const [refreshVehicleList, setRefreshVehicleList] = useState(
    crypto.randomUUID()
  );

  const onCloseModal = () => {
    setModalConfig({ ...modalConfig, isModalOpen: false });
  };
  const onSelectedVehicle = vehicle => {
    const isAlreadyAdded = saleData.vehiclesDataList.some(
      x => x.vehicleId === vehicle.vehicleId
    );
    if (!isAlreadyAdded) {
      setSaleData({
        ...saleData,
        vehiclesDataList: [...saleData.vehiclesDataList, vehicle]
      });
    } else {
      toast.warning(
        `${vehicle.vehicleInformation} ya ha sido agregado a vehículos para la venta`,
        {
          theme: 'colored'
        }
      );
    }

    onCloseModal();
  };
  const onRemoveVehicle = vehicle => {
    const newVehicleList = saleData.vehiclesDataList.filter(
      x => x.vehicleId !== vehicle.vehicleId
    );
    setSaleData({
      ...saleData,
      vehiclesDataList: newVehicleList
    });
  };

  useEffect(() => {
    setRefreshVehicleList(crypto.randomUUID());
  }, [saleData.vehiclesDataList]);

  return (
    <>
      <SubtitleWithButton
        text={'Vehículos para venta'}
        isFirstButtonHidden={saleData?.vehiclesDataList?.length === 3}
        firstButtonDisable={false}
        firstButtonText={'Agregar vehículos'}
        firstButtonOnClick={() =>
          setModalConfig({ ...modalConfig, isModalOpen: true })
        }
      />
      {saleData?.vehiclesDataList?.length === 0 ? (
        <EmptyListOfItemsContainer text={'Sin vehículos seleccionados'} />
      ) : (
        <VehiclesInformationComponent
          key={refreshVehicleList}
          saleData={saleData}
          onRemoveVehicle={onRemoveVehicle}
          setSaleData={setSaleData}
        />
      )}
      <CustomModal
        isOpen={modalConfig.isModalOpen}
        modalTitle={modalConfig.modalTitle}
        onCloseModal={onCloseModal}
        modalContent={
          <AddVehiclesModalContent
            onSelectedVehicle={onSelectedVehicle}
            allVehiclesForNewSaleData={allVehiclesForNewSaleData}
            isLoading={isLoading}
          />
        }
      />
    </>
  );
};
AddVehiclesComponent.propTypes = {
  saleData: PropTypes.object,
  setSaleData: PropTypes.func,
  isLoading: PropTypes.bool,
  allVehiclesForNewSaleData: PropTypes.object
};

AddVehiclesComponent.defaultProps = {};

export default AddVehiclesComponent;
