import React from 'react';
import AddVehiclesComponent from './AddVehicles/AddVehiclesComponent';
import AddClientComponent from './AddClient/AddClientComponent';
import PropTypes from 'prop-types';

const SelectClientAndVehicle = ({ saleData, setSaleData, newSaleConfig }) => {
  return (
    <>
      <AddClientComponent
        saleData={saleData}
        setSaleData={setSaleData}
        allClientsForNewSaleData={newSaleConfig.clientsTable}
        isLoading={newSaleConfig.isLoading}
      />
      <AddVehiclesComponent
        saleData={saleData}
        setSaleData={setSaleData}
        allVehiclesForNewSaleData={newSaleConfig.vehiclesTable}
        isLoading={newSaleConfig.isLoading}
      />
    </>
  );
};

SelectClientAndVehicle.propTypes = {
  saleData: PropTypes.object,
  setSaleData: PropTypes.func,
  allClientsForNewSaleData: PropTypes.object,
  newSaleConfig: PropTypes.object
};

SelectClientAndVehicle.defaultProps = {};

export default SelectClientAndVehicle;
