import React, { useEffect, useState } from 'react';
import { ItHasValue } from 'utils/ObjectUtils';
import { useApiGet } from 'hooks/useApi';
import BranchTableWrapper from './BranchTableWrapper';
import ConfirmationModal from '../common/ConfirmationModal';
import MainContainer from '../common/MainContainer';
import LoaderSpinner from '../common/LoaderSpinner';

const titlePage = 'Sucursales';
const SearchBranch = () => {
  const allBranchesGet = useApiGet(`api/v1/branch/allBranches`);

  const [tableData, setTableData] = useState([]);
  const [modalConfig, setModalConfig] = useState({
    isModalOpen: false,
    title: '',
    description: ''
  });

  useEffect(() => {
    allBranchesGet.dispatchGet();
  }, []);

  useEffect(() => {
    if (ItHasValue(allBranchesGet.response)) {
      setTableData(allBranchesGet.response);
    }
  }, [allBranchesGet.response]);

  const onViewBranch = branchId => {
    //TODO: add link to view branch if the user has permissions
    console.log({ branchId });
  };
  const onEditBranch = branchId => {
    //TODO: add link to edit branch if the user has permissions
    console.log({ branchId });
  };
  const onDeleteBranch = (branchId, branchName) => {
    setModalConfig({
      isModalOpen: true,
      title: 'Eliminar empleado',
      description: `¿Deseas eliminar la sucursal '${branchName}' de forma permanente?`
    });
    console.log({ branchId, branchName });
  };

  return (
    <>
      <MainContainer titlePage={titlePage}>
        {tableData.length > 0 ? (
          <BranchTableWrapper
            tableData={tableData}
            onViewBranch={onViewBranch}
            onEditBranch={onEditBranch}
            onDeleteBranch={onDeleteBranch}
          />
        ) : (
          <LoaderSpinner />
        )}
      </MainContainer>
      <ConfirmationModal
        isOpen={modalConfig.isModalOpen}
        title={modalConfig.title}
        description={modalConfig.description}
        onAcceptEvent={() => {
          setModalConfig({
            ...modalConfig,
            isModalOpen: false
          });
        }}
        onCancelEvent={() => {
          setModalConfig({
            ...modalConfig,
            isModalOpen: false
          });
        }}
      />
    </>
  );
};

export default SearchBranch;
