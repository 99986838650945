import React from 'react';
import MainContainer from '../common/MainContainer';
import { Button, Col, Row } from 'react-bootstrap';
import Input from '../common/input/Input';
import Select from '../common/Select';
import TextDivider from '../common/TextDivider';

const NewBranch = () => {
  const titlePage = 'Nueva sucursal';
  return (
    <MainContainer titlePage={titlePage} pageHeight="600px">
      <Row className="mb-5 g-3">
        <Input
          name="branchName"
          id="branchName"
          label="Nombre"
          value=""
          error=""
          placeholder="Ingresa nombre de sucursal"
          type="text"
          onChange={value => {
            console.log(value);
          }}
          maxLength={25}
          col={Col}
        />
        <Select
          name="branchManager"
          label="Encargado"
          value={''}
          error=""
          options={[
            { value: '1', label: 'Emcargado 1' },
            { value: '2', label: 'Encargado 2' }
          ]}
          type="text"
          onChange={value => {
            console.log(value);
          }}
          col={Col}
        />
      </Row>
      <TextDivider title={'Dirección'} align={'cemter'} />
      <Row className="mb-3 mt-3 g-3">
        <Input
          name="mainStreet"
          id="mainStreet"
          label="Calle principal"
          value=""
          error=""
          placeholder="Ingresa calle principal"
          type="text"
          onChange={value => {
            console.log(value);
          }}
          maxLength={25}
          col={Col}
        />
        <Input
          name="alternateStreet1"
          id="alternateStreet1"
          label="Calle alterna 1"
          value=""
          error=""
          placeholder="Ingresa alterna 1"
          type="text"
          onChange={value => {
            console.log(value);
          }}
          maxLength={25}
          col={Col}
        />
        <Input
          name="alternateStreet2"
          id="alternateStreet2"
          label="Calle alterna 2"
          value=""
          error=""
          placeholder="Ingresa alterna 2"
          type="text"
          onChange={value => {
            console.log(value);
          }}
          maxLength={25}
          col={Col}
        />
      </Row>
      <Row className="mb-3 g-3">
        <Input
          name="zipCode"
          id="zipCode"
          label="Código postal"
          value=""
          error=""
          placeholder="Ingresa el C.P"
          type="text"
          onChange={value => {
            console.log(value);
          }}
          maxLength={25}
          col={Col}
        />
        <Input
          name="externalNumber"
          id="externalNumber"
          label="N° exterior"
          value=""
          error=""
          placeholder="Ingresa Numero exterior"
          type="text"
          onChange={value => {
            console.log(value);
          }}
          maxLength={25}
          col={Col}
        />
        <Input
          name="neighborhood"
          id="neighborhood"
          label="Colonia"
          value=""
          error=""
          placeholder="Ingresa colonia"
          type="text"
          onChange={value => {
            console.log(value);
          }}
          maxLength={25}
          col={Col}
        />
        <Input
          name="city"
          id="city"
          label="Ciudad"
          value=""
          error=""
          placeholder="Ingresa ciudad"
          type="text"
          onChange={value => {
            console.log(value);
          }}
          maxLength={25}
          col={Col}
        />
      </Row>
      <Row className="mb-3 g-3">
        <Input
          name="characteristics"
          id="characteristics"
          label="Características"
          value=""
          error=""
          placeholder="Ingresa características de sucursal"
          type="text"
          onChange={value => {
            console.log(value);
          }}
          maxLength={25}
          col={Col}
        />
      </Row>

      <Row>
        <Col className="text-end">
          <Button variant="secondary" type="submit" disabled>
            Guardar nuevo empleado
          </Button>
        </Col>
      </Row>
    </MainContainer>
  );
};

export default NewBranch;
