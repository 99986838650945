import React, { useEffect, useState } from 'react';
import { ItHasValue } from 'utils/ObjectUtils';
import MainContainer from '../common/MainContainer';
import SaleTableWrapper from './SaleTableWrapper';
import LoaderSpinner from '../common/LoaderSpinner';
import ConfirmationModal from '../common/ConfirmationModal';
import { useApiGet } from 'hooks/useApi';

const titlePage = 'Ventas';
const SearchSale = () => {
  const allSalesGet = useApiGet(`api/v1/branch/allBranches`);

  const [tableData, setTableData] = useState([]);
  const [modalConfig, setModalConfig] = useState({
    isModalOpen: false,
    title: '',
    description: ''
  });

  useEffect(() => {
    allSalesGet.dispatchGet();
  }, []);

  useEffect(() => {
    if (ItHasValue(allSalesGet.response)) {
      setTableData(allSalesGet.response);
    }
  }, [allSalesGet.response]);

  const onViewSale = saleId => {
    //TODO: add link to view branch if the user has permissions
    console.log({ saleId });
  };
  const onEditSale = saleId => {
    //TODO: add link to edit branch if the user has permissions
    console.log({ saleId });
  };
  const onDeleteSale = (saleId, saleName) => {
    setModalConfig({
      isModalOpen: true,
      title: 'Eliminar venta',
      description: `¿Deseas eliminar la la venta '${saleName}' de forma permanente?`
    });
    console.log({ saleId, saleName });
  };

  return (
    <>
      <MainContainer titlePage={titlePage}>
        {tableData.length > 0 ? (
          <SaleTableWrapper
            tableData={tableData}
            onViewSale={onViewSale}
            onEditSale={onEditSale}
            onDeleteSale={onDeleteSale}
          />
        ) : (
          <LoaderSpinner />
        )}
      </MainContainer>
      <ConfirmationModal
        isOpen={modalConfig.isModalOpen}
        title={modalConfig.title}
        description={modalConfig.description}
        onAcceptEvent={() => {
          setModalConfig({
            ...modalConfig,
            isModalOpen: false
          });
        }}
        onCancelEvent={() => {
          setModalConfig({
            ...modalConfig,
            isModalOpen: false
          });
        }}
      />
    </>
  );
};

export default SearchSale;
