import React from 'react';
import PropTypes from 'prop-types';
import {
  formatStringToCamelCase,
  truncateString
} from 'components/utilities/StringUtil';
import RowActionsDropdown from '../common/RowActionsDropdown';

const CompletedVehiclesTableWrapper = ({
  tableData,
  onViewVehicle,
  onEditVehicle,
  onDeleteVehicle
}) => {
  return (
    <>
      <div className="table-responsive table-fixed">
        <table className="table overflow-hidden table-sm ">
          <thead className="table-primary">
            <tr>
              <th scope="col">Tipo vehículo</th>
              <th scope="col">Marca</th>
              <th scope="col">Linea</th>
              <th scope="col">Modelo</th>
              <th className="text-end" scope="col">
                Acciones
              </th>
            </tr>
          </thead>
          <tbody>
            {tableData?.map(item => (
              <tr className="align-middle" key={item.vehicleId}>
                <td className="text-nowrap">
                  {truncateString(formatStringToCamelCase(item.type), 30)}
                </td>
                <td className="text-nowrap">
                  {truncateString(formatStringToCamelCase(item.brand), 30)}
                </td>
                <td className="text-nowrap">
                  {truncateString(formatStringToCamelCase(item.line), 30)}
                </td>
                <td className="text-wrap">
                  {truncateString(formatStringToCamelCase(item.model), 30)}
                </td>
                <td className="text-end">
                  <RowActionsDropdown
                    itemId={item.branchId}
                    itemName={formatStringToCamelCase(item.name)}
                    onViewItem={onViewVehicle}
                    onEditItem={onEditVehicle}
                    onDeleteItem={(branchId, branchName) => {
                      onDeleteVehicle(branchId, branchName);
                    }}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

CompletedVehiclesTableWrapper.propTypes = {
  tableData: PropTypes.array.isRequired,
  onViewVehicle: PropTypes.func,
  onEditVehicle: PropTypes.func,
  onDeleteVehicle: PropTypes.func
};

CompletedVehiclesTableWrapper.defaultProps = {
  tableData: [],
  onViewVehicle: () => {},
  onEditVehicle: () => {},
  onDeleteVehicle: () => {}
};

export default CompletedVehiclesTableWrapper;
