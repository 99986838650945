import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import { Col, Row } from 'react-bootstrap';
import Input from 'components/el-chicho-jr/common/input/Input';
import { getDateFromDateTime, getTimeFromDateTime } from 'utils/DateTimeUtils';
import Label from 'components/el-chicho-jr/common/Label';
import { formatCurrencyValue } from 'components/el-chicho-jr/common/input/InputUtils';

const ClientAndVehiclesDataReadOnly = ({ saleData }) => {
  const getFullClientName = () => {
    const arrayOfClientName = [
      saleData?.clientData?.name,
      saleData?.clientData?.paternalSurname,
      saleData?.clientData?.maternalSurname
    ].filter(x => x !== '');

    return arrayOfClientName.join(' ');
  };

  return (
    <>
      <Row className="mt-1 mb-3 g-3">
        <Input
          name="saleDate"
          id="saleDate"
          label="Fecha"
          value={getDateFromDateTime(saleData.saleDetails.dateTime)}
          readOnly
          col={Col}
        />
        <Input
          name="saleTime"
          id="saleTime"
          label="Hora"
          value={getTimeFromDateTime(saleData.saleDetails.dateTime)}
          readOnly
          type="text"
          col={Col}
        />
        <Input
          name="customerName"
          id="customerName"
          label="Nombre"
          value={getFullClientName()}
          readOnly
          type="text"
          col={Col}
        />
      </Row>

      <Row key={'vehicle-title'} className=" g-3">
        <Col md={8}>
          <Form.Group className="mb-3" controlId={'vehicle-title'}>
            <Form.Label style={{ marginBottom: '0px' }}>
              {saleData?.vehiclesDataList.length > 1 ? 'Vehículos' : 'Vehículo'}
            </Form.Label>
          </Form.Group>
        </Col>
        <Col md={2}>
          <Form.Group className="mb-3" controlId={'cash-price'}>
            <Form.Label style={{ marginBottom: '0px' }}>
              {'Precio contado'}
            </Form.Label>
          </Form.Group>
        </Col>
        <Col md={2}>
          <Form.Group className="mb-3" controlId={'credit-price'}>
            <Form.Label style={{ marginBottom: '0px' }}>
              {'Precio crédito'}
            </Form.Label>
          </Form.Group>
        </Col>
      </Row>

      {saleData?.vehiclesDataList?.map(vehicle => (
        <Row key={vehicle.vehicleId} className=" g-3">
          <Col md={8}>
            <Label
              name={`customerName_${vehicle.vehicleId}`}
              id={`customerName_${vehicle.vehicleId}`}
              text={vehicle.vehicleInformation}
            />
          </Col>
          <Col md={2}>
            <Label
              name={`customerName_${vehicle.vehicleId}`}
              id={`customerName_${vehicle.vehicleId}`}
              text={formatCurrencyValue(vehicle.cashPrice?.toString())}
            />
          </Col>
          <Col md={2}>
            <Label
              name={`customerName_${vehicle.vehicleId}`}
              id={`customerName_${vehicle.vehicleId}`}
              text={formatCurrencyValue(vehicle.creditPrice?.toString())}
            />
          </Col>
        </Row>
      ))}
    </>
  );
};
ClientAndVehiclesDataReadOnly.propTypes = {
  saleData: PropTypes.object
};

ClientAndVehiclesDataReadOnly.defaultProps = {};

export default ClientAndVehiclesDataReadOnly;
