import React from 'react';
import { Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { getReadOnlyInputStyles } from './InputUtils';

const Input = ({
  id,
  name,
  label,
  value,
  error,
  placeholder,
  type,
  onChange,
  maxLength,
  readOnly,
  col
}) => {
  const handleInputChange = event => {
    const newValue = event.target.value;
    onChange?.(newValue);
  };

  return (
    <Form.Group className="mb-3" as={col} controlId={id}>
      <Form.Label>{label ?? ''}</Form.Label>
      <Form.Control
        type={type}
        name={name}
        placeholder={placeholder}
        isInvalid={error}
        value={value}
        readOnly={readOnly}
        onChange={handleInputChange}
        maxLength={maxLength}
        style={getReadOnlyInputStyles(readOnly)}
      />
      <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
    </Form.Group>
  );
};
Input.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  error: PropTypes.string,
  maxLength: PropTypes.number,
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
  col: PropTypes.any
};

Input.defaultProps = {
  id: '',
  name: '',
  label: '',
  value: '',
  placeholder: '',
  type: 'text',
  maxLength: 50,
  readOnly: false,
  error: null,
  onChange: () => {}
};
export default Input;
