import React from 'react';
import { Col, Row } from 'react-bootstrap';
import editing from 'assets/img/icons/spot-illustrations/21.png';
import FalconComponentCard from 'components/common/FalconComponentCard';
import PageHeader from 'components/common/PageHeader';
import PropTypes from 'prop-types';

const DefaultPageTemplate = ({ titlePage }) => {
  return (
    <>
      <PageHeader title={titlePage} className="mb-3"></PageHeader>
      <FalconComponentCard>
        <FalconComponentCard.Body>
          <Row className="align-items-center justify-content-between">
            <Col lg={6}>
              <img src={editing} className="img-fluid" alt="" />
            </Col>
            <Col lg={6} className="ps-lg-4 my-5 text-center text-lg-start">
              <h3 className="text-primary">Proximamante!</h3>
              <p className="lead">En desarrollo...</p>
            </Col>
          </Row>
        </FalconComponentCard.Body>
      </FalconComponentCard>
    </>
  );
};

DefaultPageTemplate.propTypes = {
  titlePage: PropTypes.string
};
export default DefaultPageTemplate;
