import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import Select from 'components/el-chicho-jr/common/Select';
import InputCurrency from 'components/el-chicho-jr/common/input/InputCurrency';
import { ItHasValue } from 'utils/ObjectUtils';

const NewSaleDetailsPayment = ({ saleData, setSaleData }) => {
  console.log(setSaleData);
  const [totalPriceValues, setTotalPriceValues] = useState({
    saleType: 'cash', //values: cash, credit
    total: 23488,
    totalDollar: 0,
    pending: 0,
    pendingDollar: 0
  });

  useEffect(() => {
    if (ItHasValue(saleData)) {
      const totalCashPrice = saleData.vehiclesDataList.reduce(
        (total, item) => total + item.cashPrice,
        0
      );
      console.log({ totalCashPrice });
      setTotalPriceValues({
        ...totalPriceValues,
        total: totalCashPrice,
        totalDollar: 0,
        pending: 0,
        pendingDollar: 0
      });
    }
  }, [saleData]);

  useEffect(() => {
    console.log({ totalPriceValues });
  }, [totalPriceValues]);

  return (
    <>
      <Row className="mt-1 mb-3 g-3">
        <Col md={3}>
          <Select
            name="saleDate"
            id="saleDate"
            label="Opciones de Compra"
            value={''}
            options={[
              { value: 'cash', label: 'Contado' },
              { value: 'credit', label: 'Credito' }
            ]}
            onChange={value => {
              console.log({ value });
              setTotalPriceValues({
                ...totalPriceValues,
                total: 5555,
                totalDollar: 0,
                pending: 0,
                pendingDollar: 0
              });
            }}
          />
        </Col>
        <Col md={1}></Col>
        <Col md={2}>
          <InputCurrency
            name={`total`}
            id={`total`}
            label="Total pesos"
            value={totalPriceValues.total.toString()}
            readOnly
          />
        </Col>
        <Col md={2}>
          <InputCurrency
            name={`total-dollar`}
            id={`total-dollar`}
            label="Total dolares"
            value={''}
            readOnly
          />
        </Col>
        <Col md={2}>
          <InputCurrency
            name={`pending`}
            id={`pending`}
            label="Pendiente pesos"
            value={''}
            readOnly
          />
        </Col>
        <Col md={2}>
          <InputCurrency
            name={`pending-dollar`}
            id={`pending-dollar`}
            label="Pendiente dolares"
            value={''}
            readOnly
          />
        </Col>
      </Row>
    </>
  );
};
NewSaleDetailsPayment.propTypes = {
  saleData: PropTypes.object,
  setSaleData: PropTypes.func
};

NewSaleDetailsPayment.defaultProps = {};

export default NewSaleDetailsPayment;
