import React, { useState } from 'react';
import PropTypes from 'prop-types';
import SubtitleWithButton from '../../../common/SubtitleWithButton';
import EmptyListOfItemsContainer from '../EmptyListOfItemsContainer';
import CustomModal from '../../../common/CustomModal';
import AddClientModalContent from './AddClientModalContent';
import ClientInformationComponent from './ClientInformationComponent';

const AddClientComponent = ({
  saleData,
  setSaleData,
  allClientsForNewSaleData,
  isLoading
}) => {
  const [modalConfig, setModalConfig] = useState({
    isModalOpen: false,
    modalTitle: 'Agregar cliente'
  });
  const [refreshClientInformation, setRefreshClientInformation] = useState(
    crypto.randomUUID()
  );
  const onCloseModal = () => {
    setModalConfig({ ...modalConfig, isModalOpen: false });
  };
  const onSelectedClient = client => {
    setSaleData({
      ...saleData,
      clientData: {
        clientId: client.clientId,
        name: client.name,
        paternalSurname: client.paternalSurname,
        maternalSurname: client.maternalSurname,
        celPhone: client.celPhone,
        homePhone: client.homePhone,
        workPhone: client.workPhone
      }
    });
    setRefreshClientInformation(crypto.randomUUID());
    onCloseModal();
  };

  return (
    <>
      <SubtitleWithButton
        text={'Cliente para venta'}
        isFirstButtonHidden={false}
        firstButtonDisable={false}
        firstButtonText={
          saleData.clientData === null ? 'Agregar cliente' : 'Cambiar cliente'
        }
        firstButtonOnClick={() =>
          setModalConfig({ ...modalConfig, isModalOpen: true })
        }
      />
      {saleData.clientData === null ? (
        <EmptyListOfItemsContainer text={'Sin cliente seleccionado'} />
      ) : (
        <ClientInformationComponent
          key={refreshClientInformation}
          saleData={saleData}
          setSaleData={setSaleData}
        />
      )}
      <CustomModal
        isOpen={modalConfig.isModalOpen}
        modalTitle={modalConfig.modalTitle}
        onCloseModal={onCloseModal}
        modalContent={
          <AddClientModalContent
            onSelectedClient={onSelectedClient}
            allClientsForNewSaleData={allClientsForNewSaleData}
            isLoading={isLoading}
          />
        }
      />
    </>
  );
};
AddClientComponent.propTypes = {
  saleData: PropTypes.object,
  setSaleData: PropTypes.func,
  isLoading: PropTypes.bool,
  allClientsForNewSaleData: PropTypes.object
};

AddClientComponent.defaultProps = {};

export default AddClientComponent;
