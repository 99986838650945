import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'react-bootstrap';

const CustomModal = ({ isOpen, modalTitle, modalContent, onCloseModal }) => {
  return (
    <Modal
      show={isOpen}
      onHide={() => onCloseModal?.()}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
    >
      <Modal.Header className="p-2">
        <Modal.Title id="contained-modal-title-vcenter">
          {modalTitle}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ minHeight: '500px', overflowY: 'auto' }}>
        {modalContent}
      </Modal.Body>
      <Modal.Footer className="border-0">
        <Button onClick={() => onCloseModal?.()}>{'Cerrar'}</Button>
      </Modal.Footer>
    </Modal>
  );
};
CustomModal.propTypes = {
  isOpen: PropTypes.bool,
  modalTitle: PropTypes.string,
  onCloseModal: PropTypes.func,
  modalContent: PropTypes.node
};

CustomModal.defaultProps = {
  isOpen: false,
  modalTitle: '',
  onCloseModal: () => {}
};

export default CustomModal;
