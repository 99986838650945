import React from 'react';

import AuthCardLayout from 'layouts/AuthCardLayout';
import LoginForm from './LoginForm';

const Login = () => {
  return (
    <AuthCardLayout
      leftSideContent={
        <p className="text-white">
          ¿No tienes una cuenta?
          <br />
          <p
            className="text-white text-decoration-underline"
            to="/authentication/card/register"
          >
            Contacta al administrador
          </p>
        </p>
      }
    >
      <h3>Inicio de sesión</h3>
      <LoginForm hasLabel />
    </AuthCardLayout>
  );
};

export default Login;
