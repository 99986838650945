import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import Input from 'components/el-chicho-jr/common/input/Input';
import InputPhone from 'components/el-chicho-jr/common/input/InputPhone';

const ClientInformationComponent = ({ saleData, setSaleData }) => {
  const updateClientData = (field, value) => {
    setSaleData({
      ...saleData,
      clientData: { ...saleData.clientData, [field]: value }
    });
  };

  return (
    <>
      <Row className="mt-4 mb-3 g-3">
        <Input
          name="customerName"
          id="customerName"
          label="Nombre"
          value={saleData?.clientData?.name}
          error=""
          placeholder="Ingresa nombre de sucursal"
          type="text"
          onChange={value => updateClientData('name', value)}
          maxLength={50}
          col={Col}
        />
        <Input
          name="customerLastName"
          id="customerLastName"
          label="Apellido paterno"
          value={saleData?.clientData?.paternalSurname}
          error=""
          placeholder="Ingresa apellido paterno"
          type="text"
          onChange={value => updateClientData('paternalSurname', value)}
          maxLength={50}
          col={Col}
        />
        <Input
          name="customerMiddleName"
          id="customerMiddleName"
          label="Apellido materno"
          value={saleData?.clientData?.maternalSurname}
          error=""
          placeholder="Ingresa apellido materno"
          type="text"
          onChange={value => updateClientData('maternalSurname', value)}
          maxLength={50}
          col={Col}
        />
      </Row>
      <Row className="mb-4 g-3">
        <InputPhone
          name="customerPhone"
          id="customerPhone"
          label="Celular"
          placeholder="Ingresa celular"
          value={saleData?.clientData?.celPhone}
          error=""
          onChange={value => updateClientData('celPhone', value)}
          col={Col}
        />
        <InputPhone
          name="customerHomePhone"
          id="customerHomePhone"
          label="Teléfono de casa"
          value={saleData?.clientData?.homePhone}
          error=""
          placeholder="Ingresa teléfono de casa"
          type="text"
          onChange={value => updateClientData('homePhone', value)}
          col={Col}
        />
        <InputPhone
          name="customerWorkPhone"
          id="customerWorkPhone"
          label="Teléfono de trabajo"
          value={saleData?.clientData?.workPhone}
          error=""
          placeholder="Ingresa teléfono de trabajo"
          type="text"
          onChange={value => updateClientData('workPhone', value)}
          col={Col}
        />
      </Row>
    </>
  );
};
ClientInformationComponent.propTypes = {
  saleData: PropTypes.object,
  setSaleData: PropTypes.func
};

ClientInformationComponent.defaultProps = {};
export default ClientInformationComponent;
