import React from 'react';
import PageHeader from 'components/common/PageHeader';
import PropTypes from 'prop-types';

const PageTitleHeader = ({ titlePage }) => {
  return <PageHeader title={titlePage} className="mb-3" />;
};

PageTitleHeader.propTypes = {
  titlePage: PropTypes.string
};
export default PageTitleHeader;
