import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from 'context/Context';

const LoginForm = ({ hasLabel, layout }) => {
  const { login } = useContext(AuthContext);

  const navigate = useNavigate();
  const handleClick = () => navigate('/');

  const [formData, setFormData] = useState({
    email: '',
    password: '',
    remember: false,
    showForgotPasswordMessage: false
  });

  useEffect(() => {
    console.log({ layout });
  }, []);

  const defaultEmail = 'prueba1@gmail.com';
  const defaultPassword = '123';
  // Handler
  const handleSubmit = e => {
    e.preventDefault();

    if (
      formData.email === defaultEmail &&
      formData.password === defaultPassword
    ) {
      login('asdasdasdasss1');
      toast.success(`Inicio de sesion como ${formData.email}`, {
        theme: 'colored'
      });
      handleClick();
    } else {
      toast.error(`Correo o contraseña incorrectos`, {
        theme: 'colored'
      });
    }
  };

  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Correo</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'Correo' : ''}
          value={formData.email}
          name="email"
          onChange={handleFieldChange}
          type="email"
        />
      </Form.Group>

      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Contraseña</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'Contraseña' : ''}
          value={formData.password}
          name="password"
          onChange={handleFieldChange}
          type="password"
        />
      </Form.Group>

      <Row className="justify-content-between align-items-center">
        <Col xs="auto"></Col>

        <Col xs="auto">
          <p
            className="fs--1 mb-0"
            // to={`/authentication/${layout}/forgot-password`}
          >
            Forgot Password?
          </p>
        </Col>
      </Row>

      <Form.Group>
        <Button
          type="submit"
          color="primary"
          className="mt-3 w-100"
          disabled={!formData.email || !formData.password}
        >
          Log in
        </Button>
      </Form.Group>

      {/* <Divider className="mt-4">or log in with</Divider> */}

      {/* <SocialAuthButtons /> */}
    </Form>
  );
};

LoginForm.propTypes = {
  layout: PropTypes.string,
  hasLabel: PropTypes.bool
};

LoginForm.defaultProps = {
  layout: 'simple',
  hasLabel: false
};

export default LoginForm;
