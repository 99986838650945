import React from 'react';
import {
  formatStringNumberPhone,
  formatStringToCamelCase
} from 'components/utilities/StringUtil';
import RowActionsDropdown from '../common/RowActionsDropdown';
import PropTypes from 'prop-types';

const EmployeeTableWrapper = ({
  tableData,
  onViewEmployee,
  onEditEmployee,
  onDeleteEmployee
}) => {
  return (
    <>
      <div className="table-responsive scrollbar">
        <table className="table overflow-hidden table-sm ">
          <thead className="table-primary">
            <tr>
              <th scope="col">Nombre</th>
              <th scope="col">Correo</th>
              <th scope="col">Celular</th>
              <th scope="col">Sucursal</th>
              <th className="text-end" scope="col">
                Acciones
              </th>
            </tr>
          </thead>
          <tbody>
            {tableData?.map(item => (
              <tr className="align-middle" key={item.employeeId}>
                <td className="text-nowrap">
                  <div className="d-flex align-items-center">
                    <div className="avatar avatar-xl">
                      <div className="avatar-name rounded-circle bg-secondary">
                        <span>{item.avatarName}</span>
                      </div>
                    </div>
                    <div className="ms-2">
                      {formatStringToCamelCase(item.name + ' ' + item.lastName)}
                    </div>
                  </div>
                </td>
                <td className="text-nowrap">{item.email}</td>
                <td className="text-nowrap">
                  {formatStringNumberPhone(item.phone)}
                </td>
                <td className="text-wrap">
                  {formatStringToCamelCase(item.branchName)}
                </td>
                <td className="text-end">
                  <RowActionsDropdown
                    itemId={item.employeeId}
                    itemName={formatStringToCamelCase(
                      item.name + ' ' + item.lastName
                    )}
                    onViewItem={onViewEmployee}
                    onEditItem={onEditEmployee}
                    onDeleteItem={(employeeId, employeeName) => {
                      onDeleteEmployee(employeeId, employeeName);
                    }}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

EmployeeTableWrapper.propTypes = {
  tableData: PropTypes.array.isRequired,
  onViewEmployee: PropTypes.func,
  onEditEmployee: PropTypes.func,
  onDeleteEmployee: PropTypes.func
};

EmployeeTableWrapper.defaultProps = {
  tableData: [],
  onViewEmployee: () => {},
  onEditEmployee: () => {},
  onDeleteEmployee: () => {}
};
export default EmployeeTableWrapper;
