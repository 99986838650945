import { useApiGet } from 'hooks/useApi';
import React, { useEffect, useState } from 'react';
import { ItHasValue } from 'utils/ObjectUtils';
import MainContainer from '../common/MainContainer';
import LoaderSpinner from '../common/LoaderSpinner';
import ConfirmationModal from '../common/ConfirmationModal';
import { Tab, Tabs } from 'react-bootstrap';
import AvailableVehiclesTableWrapper from './AvailableVehiclesTableWrapper';
import PendingVehiclesTableWrapper from './PendingVehiclesTableWrapper';
import ReservedVehiclesTableWrapper from './ReservedVehiclesTableWrapper';
import CompletedVehiclesTableWrapper from './CompletedVehiclesTableWrapper';

const titlePage = 'Vehículos';
const SearchVehicle = () => {
  const allVehicles = useApiGet(`api/v1/vehicle/allVehicles`);

  const [tableData, setTableData] = useState();
  const [modalConfig, setModalConfig] = useState({
    isModalOpen: false,
    title: '',
    description: ''
  });

  useEffect(() => {
    allVehicles.dispatchGet();
  }, []);

  useEffect(() => {
    if (ItHasValue(allVehicles.response)) {
      setTableData(allVehicles.response);
    }
  }, [allVehicles.response]);

  const onViewVehicle = vehicleId => {
    //TODO: add link to view branch if the user has permissions
    console.log({ vehicleId });
  };
  const onEditVehicle = vehicleId => {
    //TODO: add link to edit branch if the user has permissions
    console.log({ vehicleId });
  };
  const onDeleteVehicle = (vehicleId, vehicleName) => {
    setModalConfig({
      isModalOpen: true,
      title: 'Eliminar Vehiculo',
      description: `¿Deseas eliminar el vehiculo '${vehicleName}' de forma permanente?`
    });
    console.log({ vehicleId, vehicleName });
  };

  return (
    <>
      <MainContainer titlePage={titlePage}>
        <Tabs
          defaultActiveKey="available"
          id="uncontrolled-tab-example"
          transition={false}
        >
          <Tab
            eventKey="available"
            title="Disponible para venta"
            className=" pt-3"
          >
            {tableData ? (
              <AvailableVehiclesTableWrapper
                tableData={tableData.availableVehicles ?? []}
                onViewVehicle={onViewVehicle}
                onEditVehicle={onEditVehicle}
                onDeleteVehicle={onDeleteVehicle}
              />
            ) : (
              <LoaderSpinner />
            )}
          </Tab>
          <Tab eventKey="pending" title="En venta" className=" pt-3">
            {tableData ? (
              <PendingVehiclesTableWrapper
                tableData={tableData.pendingVehicles ?? []}
                onViewVehicle={onViewVehicle}
                onEditVehicle={onEditVehicle}
                onDeleteVehicle={onDeleteVehicle}
              />
            ) : (
              <LoaderSpinner />
            )}
          </Tab>
          <Tab eventKey="reserved" title="Apartado" className=" pt-3">
            {tableData ? (
              <ReservedVehiclesTableWrapper
                tableData={tableData.reservedVehicles ?? []}
                onViewVehicle={onViewVehicle}
                onEditVehicle={onEditVehicle}
                onDeleteVehicle={onDeleteVehicle}
              />
            ) : (
              <LoaderSpinner />
            )}
          </Tab>

          <Tab eventKey="completed" title="Vendidos" className=" pt-3">
            {tableData ? (
              <CompletedVehiclesTableWrapper
                tableData={tableData.completedVehicles ?? []}
                onViewVehicle={onViewVehicle}
                onEditVehicle={onEditVehicle}
                onDeleteVehicle={onDeleteVehicle}
              />
            ) : (
              <LoaderSpinner />
            )}
          </Tab>
        </Tabs>
      </MainContainer>
      <ConfirmationModal
        isOpen={modalConfig.isModalOpen}
        title={modalConfig.title}
        description={modalConfig.description}
        onAcceptEvent={() => {
          setModalConfig({
            ...modalConfig,
            isModalOpen: false
          });
        }}
        onCancelEvent={() => {
          setModalConfig({
            ...modalConfig,
            isModalOpen: false
          });
        }}
      />
    </>
  );
};

export default SearchVehicle;
