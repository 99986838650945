import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import Input from 'components/el-chicho-jr/common/input/Input';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import InputCurrency from 'components/el-chicho-jr/common/input/InputCurrency';

const VehiclesInformationComponent = ({
  saleData,
  onRemoveVehicle,
  setSaleData
}) => {
  const onChangeCashPrice = (vehicleId, value) => {
    const vehicles = saleData.vehiclesDataList.map(x => {
      if (x.vehicleId === vehicleId) {
        x.cashPrice = value;
      }
      return x;
    });
    setSaleData({
      ...saleData,
      vehiclesDataList: vehicles
    });
  };

  const onChangeCreditPrice = (vehicleId, value) => {
    const vehicles = saleData.vehiclesDataList.map(x => {
      if (x.vehicleId === vehicleId) {
        x.creditPrice = value;
      }
      return x;
    });
    setSaleData({
      ...saleData,
      vehiclesDataList: vehicles
    });
  };

  return (
    <div className="mt-4">
      {saleData?.vehiclesDataList?.map(vehicle => (
        <Row key={crypto.randomUUID()} className=" g-3">
          <Col md={7}>
            <Input
              name={`vehicle_${vehicle.vehicleId}`}
              id={`vehicle_${vehicle.vehicleId}`}
              label="Vehículo"
              value={vehicle.vehicleInformation}
              error=""
              type="text"
              readOnly
              maxLength={50}
            />
          </Col>
          <Col md={2}>
            <InputCurrency
              name={`cashPrice-${vehicle.vehicleId}`}
              id={`cashPrice-${vehicle.vehicleId}`}
              label="Precio contado"
              value={vehicle.cashPrice?.toString()}
              error=""
              onChange={value => {
                onChangeCashPrice(vehicle.vehicleId, value);
              }}
            />
          </Col>
          <Col md={2}>
            <InputCurrency
              name={`creditPrice-${vehicle.vehicleId}`}
              id={`creditPrice-${vehicle.vehicleId}`}
              label="Precio crédito"
              value={vehicle.creditPrice?.toString()}
              error=""
              readOnly={false}
              onChange={value => {
                onChangeCreditPrice(vehicle.vehicleId, value);
              }}
            />
          </Col>
          <Col
            md={1}
            className="d-flex justify-content-center align-items-center"
          >
            <FontAwesomeIcon
              icon={'trash'}
              className="text-danger fa-2x cursor-pointer mt-2"
              onClick={() => {
                onRemoveVehicle?.(vehicle);
              }}
            />
          </Col>
        </Row>
      ))}
    </div>
  );
};
VehiclesInformationComponent.propTypes = {
  saleData: PropTypes.object,
  onRemoveVehicle: PropTypes.func,
  setSaleData: PropTypes.func
};

VehiclesInformationComponent.defaultProps = {
  vehiclesDataList: []
};
export default VehiclesInformationComponent;
