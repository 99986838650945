import React from 'react';

const LoaderSpinner = () => {
  return (
    <div className="d-flex justify-content-center align-items-center mt-5">
      <div className="me-2 spinner-border text-danger" role="status" />
      {'Cargando...'}
    </div>
  );
};

export default LoaderSpinner;
