import { ItHasValue } from 'utils/ObjectUtils';

export const formatStringToCamelCase = value => {
  if (typeof value !== 'string') {
    return '';
  }
  const lowerCaseString = value.toLowerCase();
  const words = lowerCaseString.split(/[-_ ]+/);
  const camelCaseWords = words.map(word => {
    return word.charAt(0).toUpperCase() + word.slice(1);
  });
  const camelCaseValue = camelCaseWords.join(' ');
  return camelCaseValue;
};

export const formatStringNumberPhone = phoneNumber => {
  if (!ItHasValue(phoneNumber)) {
    return '';
  }
  const cleanNumber = phoneNumber.replace(/\D/g, '');
  const formattedNumber = cleanNumber.replace(
    /^(\d{3})(\d{3})(\d{4})$/,
    '($1) $2-$3'
  );
  return formattedNumber;
};

export const truncateString = (valueStr, maxCharacters) => {
  if (!ItHasValue(valueStr)) {
    return '';
  }
  const symbol = valueStr.length > maxCharacters ? '...' : '';
  return valueStr.substring(0, maxCharacters) + symbol;
};

export const currencyFormatter = valueStr => {
  if (!ItHasValue(valueStr)) {
    return '';
  }
  const currencyFormat = new Intl.NumberFormat('es-MX', {
    style: 'currency',
    currency: 'MXN'
  });
  return currencyFormat.format(valueStr);
};
