import React from 'react';
import {
  formatStringNumberPhone,
  formatStringToCamelCase,
  truncateString
} from 'components/utilities/StringUtil';
import PropTypes from 'prop-types';
import RowActionsDropdown from '../common/RowActionsDropdown';

const BranchTableWrapper = ({
  tableData,
  onViewBranch,
  onEditBranch,
  onDeleteBranch
}) => {
  return (
    <>
      <div className="table-responsive scrollbar">
        <table className="table overflow-hidden table-sm ">
          <thead className="table-primary">
            <tr>
              <th scope="col">Nombre</th>
              <th scope="col">Dirección</th>
              <th scope="col">Administrador</th>
              <th scope="col">Celular</th>
              <th className="text-end" scope="col">
                Acciones
              </th>
            </tr>
          </thead>
          <tbody>
            {tableData?.map(item => (
              <tr className="align-middle" key={item.branchId}>
                <td className="text-nowrap">
                  {truncateString(formatStringToCamelCase(item.name), 30)}
                </td>
                <td className="text-nowrap">
                  {truncateString(formatStringToCamelCase(item.address), 30)}
                </td>
                <td className="text-nowrap">
                  {formatStringToCamelCase(item.managerName)}
                </td>
                <td className="text-wrap">
                  {formatStringNumberPhone(item.managerPhone)}
                </td>
                <td className="text-end">
                  <RowActionsDropdown
                    itemId={item.branchId}
                    itemName={formatStringToCamelCase(item.name)}
                    onViewItem={onViewBranch}
                    onEditItem={onEditBranch}
                    onDeleteItem={(branchId, branchName) => {
                      onDeleteBranch(branchId, branchName);
                    }}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

BranchTableWrapper.propTypes = {
  tableData: PropTypes.array.isRequired,
  onViewBranch: PropTypes.func,
  onEditBranch: PropTypes.func,
  onDeleteBranch: PropTypes.func
};

BranchTableWrapper.defaultProps = {
  tableData: [],
  onViewBranch: () => {},
  onEditBranch: () => {},
  onDeleteBranch: () => {}
};

export default BranchTableWrapper;
