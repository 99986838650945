import { ColumnTypeEnum } from 'components/utilities/BaseTableUtils';
import PropTypes from 'prop-types';
import React from 'react';
import PillBadgeCell from './PillBadgeCell';
import TextCell from './TextCell';
import NumberCell from './NumberCell';

const TableCell = ({ item, column }) => {
  const cellContent = () => {
    switch (column.type) {
      case ColumnTypeEnum.TEXT:
        return <TextCell text={item[column.columnName]} />;
      case ColumnTypeEnum.NUMBER:
        return <NumberCell text={item[column.columnName]} />;
      case ColumnTypeEnum.PILL_BADGE:
        return <PillBadgeCell text={item[column.columnName]} />;
      default:
        return <span>{item[column.columnName]}</span>;
    }
  };

  return (
    <td className="text-nowrap" style={{ height: '30px' }}>
      {cellContent()}
    </td>
  );
};

TableCell.propTypes = {
  item: PropTypes.any,
  column: PropTypes.any
};
TableCell.defaultProps = {};

export default TableCell;
