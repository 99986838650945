import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import MainLayout from '../layouts/MainLayout';
import ErrorLayout from '../layouts/ErrorLayout';
import Landing from 'components/pages/landing/Landing';
import Error404 from 'components/errors/Error404';
import Error500 from 'components/errors/Error500';
import PendingBilling from 'components/el-chicho-jr/main/PendingBilling';
import NewSaleBodyWrapper from 'components/el-chicho-jr/sale/NewSale/NewSaleBodyWrapper';
import SearchSale from 'components/el-chicho-jr/sale/SearchSale';
import NewCustomer from 'components/el-chicho-jr/customer/NewCustomer';
import SearchCustomer from 'components/el-chicho-jr/customer/SearchCustomer';
import DebtorsTable from 'components/el-chicho-jr/debtors/DebtorsTable';
import SearchBranch from 'components/el-chicho-jr/branch/SearchBranch';
import NewBranch from 'components/el-chicho-jr/branch/NewBranch';
import SearchEmployee from 'components/el-chicho-jr/employee/SearchEmployee';
import NewEmployee from 'components/el-chicho-jr/employee/NewEmployee';
import NewVehicle from 'components/el-chicho-jr/vehicle/NewVehicle';
import SearchVehicle from 'components/el-chicho-jr/vehicle/SearchVehicle';
import AllReports from 'components/el-chicho-jr/report/AllReports';
import Profits from 'components/el-chicho-jr/profits/Profits';
import Configuration from 'components/el-chicho-jr/configuration/Configuration';
import Login from 'components/el-chicho-jr/authentication/Login';
import Sandbox from 'components/el-chicho-jr/Sandbox';

const ElChichoRoutes = () => {
  return (
    <Routes>
      <Route element={<MainLayout />}>
        <Route path="/" element={<PendingBilling />} />

        <Route path="/sale/new" element={<NewSaleBodyWrapper />} />
        <Route path="/sale/all" element={<SearchSale />} />

        <Route path="/customer/new" element={<NewCustomer />} />
        <Route path="/customer/all" element={<SearchCustomer />} />

        <Route path="/debtors" element={<DebtorsTable />} />

        <Route path="/branch/new" element={<NewBranch />} />
        <Route path="/branch/all" element={<SearchBranch />} />

        <Route path="/employee/new" element={<NewEmployee />} />
        <Route path="/employee/all" element={<SearchEmployee />} />

        <Route path="/vehicle/new" element={<NewVehicle />} />
        <Route path="/vehicle/all" element={<SearchVehicle />} />

        <Route path="/report/all" element={<AllReports />} />
        <Route path="/profits" element={<Profits />} />
        <Route path="/configuration" element={<Configuration />} />
      </Route>
      <Route path="authentication/login" element={<Login />} />
      <Route path="sandbox" element={<Sandbox />} />

      <Route path="landing" element={<Landing />} />
      <Route element={<ErrorLayout />}>
        <Route path="errors/404" element={<Error404 />} />
        <Route path="errors/500" element={<Error500 />} />
      </Route>

      <Route path="*" element={<Navigate to="/errors/404" replace />} />
    </Routes>
  );
};

export default ElChichoRoutes;
