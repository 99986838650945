const months = [
  'enero',
  'febrero',
  'marzo',
  'abril',
  'mayo',
  'junio',
  'julio',
  'agosto',
  'septiembre',
  'octubre',
  'noviembre',
  'diciembre'
];

export const getTimeFromDateTime = dateTime => {
  const hours = dateTime.getHours();
  const minutes = dateTime.getMinutes();
  const amOrPm = hours >= 12 ? 'pm' : 'am';
  const formattedHours = hours % 12 || 12;
  return (
    formattedHours + ':' + (minutes < 10 ? '0' : '') + minutes + ' ' + amOrPm
  );
};

export const getDateFromDateTime = dateTime => {
  const day = dateTime.getDate();
  const month = months[dateTime.getMonth()];
  const year = dateTime.getFullYear();
  return `${day} de ${month} del ${year}`;
};
