export const inputStyles = {
  marginTop: '-6px'
};
export const getReadOnlyInputStyles = readOnly => {
  if (readOnly === true) {
    return {
      ...inputStyles,
      border: 'none',
      boxShadow: 'none'
    };
  }
  return inputStyles;
};

export const formatCurrencyValue = currencyValue => {
  if (currencyValue === '') {
    return '';
  }
  const parts = currencyValue.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return parts.join('.');
};
