import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import FalconCloseButton from 'components/common/FalconCloseButton';
import PropTypes from 'prop-types';

const ConfirmationModal = ({
  isOpen,
  title,
  description,
  cancelButtonText,
  acceptButtonText,
  onAcceptEvent,
  onCancelEvent
}) => {
  return (
    <Modal show={isOpen}>
      <Modal.Header>
        <Modal.Title>{title}</Modal.Title>
        <FalconCloseButton onClick={onCancelEvent} />
      </Modal.Header>
      <Modal.Body>{description}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onCancelEvent}>
          {cancelButtonText}
        </Button>
        <Button variant="primary" onClick={onAcceptEvent}>
          {acceptButtonText}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
ConfirmationModal.propTypes = {
  isOpen: PropTypes.bool,
  title: PropTypes.string,
  description: PropTypes.string,
  cancelButtonText: PropTypes.string,
  acceptButtonText: PropTypes.string,
  onAcceptEvent: PropTypes.func,
  onCancelEvent: PropTypes.func
};

ConfirmationModal.defaultProps = {
  isOpen: false,
  title: '',
  description: '',
  cancelButtonText: 'Cancelar',
  acceptButtonText: 'Aceptar',
  onAcceptEvent: () => {},
  onCancelEvent: () => {}
};
export default ConfirmationModal;
