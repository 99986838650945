import React from 'react';
import MainContainer from '../common/MainContainer';
import Input from '../common/input/Input';
import { Button, Col, Row } from 'react-bootstrap';
import TextDivider from '../common/TextDivider';

const titlePage = 'Nuevo cliente';
const NewCustomer = () => {
  return (
    <MainContainer titlePage={titlePage} pageHeight="600px">
      <Row className="mb-3 g-3">
        <Input
          name="customerName"
          id="customerName"
          label="Nombre"
          value=""
          error=""
          placeholder="Ingresa nombre de sucursal"
          type="text"
          onChange={value => {
            console.log(value);
          }}
          maxLength={25}
          col={Col}
        />
        <Input
          name="customerLastName"
          id="customerLastName"
          label="Apellido paterno"
          value=""
          error=""
          placeholder="Ingresa apellido paterno"
          type="text"
          onChange={value => {
            console.log(value);
          }}
          maxLength={25}
          col={Col}
        />
        <Input
          name="customerMiddleName"
          id="customerMiddleName"
          label="Apellido materno"
          value=""
          error=""
          placeholder="Ingresa apellido materno"
          type="text"
          onChange={value => {
            console.log(value);
          }}
          maxLength={25}
          col={Col}
        />
      </Row>
      <Row className="mb-3 mb-5 g-3">
        <Input
          name="customerPhone"
          id="customerPhone"
          label="Celular"
          value=""
          error=""
          placeholder="Ingresa celular"
          type="text"
          onChange={value => {
            console.log(value);
          }}
          maxLength={25}
          col={Col}
        />
        <Input
          name="customerHomePhone"
          id="customerHomePhone"
          label="Teléfono de casa"
          value=""
          error=""
          placeholder="Ingresa teléfono de casa"
          type="text"
          onChange={value => {
            console.log(value);
          }}
          maxLength={25}
          col={Col}
        />
        <Input
          name="customerWorkPhone"
          id="customerWorkPhone"
          label="Teléfono de trabajo"
          value=""
          error=""
          placeholder="Ingresa teléfono de trabajo"
          type="text"
          onChange={value => {
            console.log(value);
          }}
          maxLength={25}
          col={Col}
        />
      </Row>
      <TextDivider title={'Dirección cliente'} align={'cemter'} />
      <Row className="mb-3 mt-3 g-3">
        <Input
          name="mainStreet"
          id="mainStreet"
          label="Calle principal"
          value=""
          error=""
          placeholder="Ingresa calle principal"
          type="text"
          onChange={value => {
            console.log(value);
          }}
          maxLength={25}
          col={Col}
        />
        <Input
          name="alternateStreet1"
          id="alternateStreet1"
          label="Calle alterna 1"
          value=""
          error=""
          placeholder="Ingresa alterna 1"
          type="text"
          onChange={value => {
            console.log(value);
          }}
          maxLength={25}
          col={Col}
        />
        <Input
          name="alternateStreet2"
          id="alternateStreet2"
          label="Calle alterna 2"
          value=""
          error=""
          placeholder="Ingresa alterna 2"
          type="text"
          onChange={value => {
            console.log(value);
          }}
          maxLength={25}
          col={Col}
        />
      </Row>
      <Row className="mb-3 g-3">
        <Input
          name="zipCode"
          id="zipCode"
          label="Código postal"
          value=""
          error=""
          placeholder="Ingresa el C.P"
          type="text"
          onChange={value => {
            console.log(value);
          }}
          maxLength={25}
          col={Col}
        />
        <Input
          name="externalNumber"
          id="externalNumber"
          label="N° exterior"
          value=""
          error=""
          placeholder="Ingresa Numero exterior"
          type="text"
          onChange={value => {
            console.log(value);
          }}
          maxLength={25}
          col={Col}
        />
        <Input
          name="neighborhood"
          id="neighborhood"
          label="Colonia"
          value=""
          error=""
          placeholder="Ingresa colonia"
          type="text"
          onChange={value => {
            console.log(value);
          }}
          maxLength={25}
          col={Col}
        />
        <Input
          name="city"
          id="city"
          label="Ciudad"
          value=""
          error=""
          placeholder="Ingresa ciudad"
          type="text"
          onChange={value => {
            console.log(value);
          }}
          maxLength={25}
          col={Col}
        />
      </Row>
      <Row className="mb-3 mb-5 g-3">
        <Input
          name="characteristics"
          id="characteristics"
          label="Características"
          value=""
          error=""
          placeholder="Ingresa características de domicilio del cliente"
          type="text"
          onChange={value => {
            console.log(value);
          }}
          maxLength={25}
          col={Col}
        />
      </Row>
      <TextDivider title={'Dirección trabajo del cliente'} align={'cemter'} />
      <Row className="mb-3 mt-3 g-3">
        <Input
          name="mainStreet"
          id="mainStreet"
          label="Calle principal"
          value=""
          error=""
          placeholder="Ingresa calle principal"
          type="text"
          onChange={value => {
            console.log(value);
          }}
          maxLength={25}
          col={Col}
        />
        <Input
          name="alternateStreet1"
          id="alternateStreet1"
          label="Calle alterna 1"
          value=""
          error=""
          placeholder="Ingresa alterna 1"
          type="text"
          onChange={value => {
            console.log(value);
          }}
          maxLength={25}
          col={Col}
        />
        <Input
          name="alternateStreet2"
          id="alternateStreet2"
          label="Calle alterna 2"
          value=""
          error=""
          placeholder="Ingresa alterna 2"
          type="text"
          onChange={value => {
            console.log(value);
          }}
          maxLength={25}
          col={Col}
        />
      </Row>
      <Row className="mb-3 g-3">
        <Input
          name="zipCode"
          id="zipCode"
          label="Código postal"
          value=""
          error=""
          placeholder="Ingresa el C.P"
          type="text"
          onChange={value => {
            console.log(value);
          }}
          maxLength={25}
          col={Col}
        />
        <Input
          name="externalNumber"
          id="externalNumber"
          label="N° exterior"
          value=""
          error=""
          placeholder="Ingresa Numero exterior"
          type="text"
          onChange={value => {
            console.log(value);
          }}
          maxLength={25}
          col={Col}
        />
        <Input
          name="neighborhood"
          id="neighborhood"
          label="Colonia"
          value=""
          error=""
          placeholder="Ingresa colonia"
          type="text"
          onChange={value => {
            console.log(value);
          }}
          maxLength={25}
          col={Col}
        />
        <Input
          name="city"
          id="city"
          label="Ciudad"
          value=""
          error=""
          placeholder="Ingresa ciudad"
          type="text"
          onChange={value => {
            console.log(value);
          }}
          maxLength={25}
          col={Col}
        />
      </Row>
      <Row className="mb-3 g-3">
        <Input
          name="characteristics"
          id="characteristics"
          label="Características"
          value=""
          error=""
          placeholder="Ingresa características de trabajo del cliente"
          type="text"
          onChange={value => {
            console.log(value);
          }}
          maxLength={25}
          col={Col}
        />
      </Row>
      <Row>
        <Col className="text-end">
          <Button variant="secondary" type="submit" disabled>
            Guardar nuevo empleado
          </Button>
        </Col>
      </Row>
    </MainContainer>
  );
};

export default NewCustomer;
