import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ItHasValue } from 'utils/ObjectUtils';
import RowActionsDropdown from '../RowActionsDropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Form } from 'react-bootstrap';
import {
  faSearch,
  faSort,
  faSortDown,
  faSortUp,
  faTimesCircle
} from '@fortawesome/free-solid-svg-icons';
import LoaderSpinner from '../LoaderSpinner';
import TableCell from './BaseTableCell';

const BaseTable = ({
  columns,
  data,
  paginationNumber,
  searchBarPlaceHolder,
  searchBarWidth,
  errorNoDataToShow,
  onViewItem,
  onEditItem,
  onDeleteItem,
  onRowClick,
  onExportPdf,
  isLoading
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState(null);

  const handleSort = column => {
    if (sortColumn === column) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortColumn(column);
      setSortDirection('asc');
    }
  };

  const handlePageChange = pageNumber => {
    setCurrentPage(pageNumber);
  };

  const handleSearchChange = event => {
    setSearchTerm(event.target.value);
  };

  const filteredData = data.filter(item =>
    Object.values(item).some(val =>
      val.toString().toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  const sortedData = filteredData.sort((a, b) => {
    if (a[sortColumn] < b[sortColumn]) {
      return sortDirection === 'asc' ? -1 : 1;
    }
    if (a[sortColumn] > b[sortColumn]) {
      return sortDirection === 'asc' ? 1 : -1;
    }
    return 0;
  });

  const paginatedData = sortedData.slice(
    (currentPage - 1) * paginationNumber,
    currentPage * paginationNumber
  );

  const totalPages = Math.ceil(filteredData.length / paginationNumber);
  const pageNumbers = [];

  if (totalPages <= 6) {
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(i);
    }
  } else {
    if (currentPage <= 3) {
      for (let i = 1; i <= 4; i++) {
        pageNumbers.push(i);
      }
      pageNumbers.push('...');
      pageNumbers.push(totalPages);
    } else if (currentPage >= totalPages - 2) {
      pageNumbers.push(1);
      pageNumbers.push('...');
      for (let i = totalPages - 3; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      pageNumbers.push(1);
      pageNumbers.push('...');
      for (let i = currentPage - 1; i <= currentPage + 1; i++) {
        pageNumbers.push(i);
      }
      pageNumbers.push('...');
      pageNumbers.push(totalPages);
    }
  }

  useEffect(() => {
    setCurrentPage(1);
  }, [searchTerm, sortColumn, sortDirection]);

  return (
    <div className="table-responsive scrollbar">
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div
          className="mt-2 mb-2"
          style={{ position: 'relative', width: searchBarWidth }}
        >
          <Form.Control
            type="text"
            placeholder={searchBarPlaceHolder}
            aria-label="Search"
            className=" search-input shadow-none"
            value={searchTerm}
            onChange={handleSearchChange}
            style={{ paddingLeft: '30px' }}
          />
          <FontAwesomeIcon
            icon={faSearch}
            className="text-400 search-box-icon"
            style={{ position: 'absolute', top: '10px', left: '10px' }}
          />
          {searchTerm !== '' && (
            <FontAwesomeIcon
              icon={faTimesCircle}
              className="text-400 search-box-icon"
              style={{ position: 'absolute', top: '10px', right: '10px' }}
              onClick={() => setSearchTerm('')}
            />
          )}
        </div>
        <div>
          {onExportPdf && (
            <button
              type="button"
              className="btn btn-outline-primary mt-2"
              onClick={() => onExportPdf?.()}
            >
              Exportar como PDF
            </button>
          )}
        </div>
      </div>
      <table className="table overflow-hidden table-sm table-hover">
        <thead className="table-primary">
          <tr>
            {columns
              .filter(column => !column.isHidden)
              .map((column, index) => (
                <th key={index} scope="col" onClick={() => handleSort(column)}>
                  {column.displayColumnName}
                  {sortColumn === column && (
                    <FontAwesomeIcon
                      icon={sortDirection === 'asc' ? faSortUp : faSortDown}
                      className="ms-2 text-secondary"
                    />
                  )}
                  {sortColumn !== column && (
                    <FontAwesomeIcon
                      icon={faSort}
                      className="ms-2 text-secondary"
                    />
                  )}
                </th>
              ))}
            {(ItHasValue(onViewItem) ||
              ItHasValue(onEditItem) ||
              ItHasValue(onDeleteItem)) && (
              <th className="text-end" scope="col">
                Acciones
              </th>
            )}
          </tr>
        </thead>
        <tbody>
          {paginatedData?.length > 0 &&
            paginatedData?.map((item, index) => (
              <tr
                onClick={() => onRowClick?.(item)}
                className="align-middle"
                key={`${index}-${item}`}
                style={{ cursor: ItHasValue(onRowClick) ? 'pointer' : '' }}
              >
                {columns
                  .filter(column => !column.isHidden)
                  .map((column, celIndex) => (
                    <TableCell key={celIndex} item={item} column={column} />
                  ))}
                {(ItHasValue(onViewItem) ||
                  ItHasValue(onEditItem) ||
                  ItHasValue(onDeleteItem)) && (
                  <td className="text-end">
                    <RowActionsDropdown
                      itemId={item.branchId}
                      itemName={item.name}
                      onViewItem={onViewItem}
                      onEditItem={onEditItem}
                      onDeleteItem={onDeleteItem}
                    />
                  </td>
                )}
              </tr>
            ))}
        </tbody>
      </table>
      {paginatedData?.length === 0 && isLoading === false && (
        <div className="text-center container-fluid h5 text-secondary h-100 pt-5 pb-5">
          {errorNoDataToShow}
        </div>
      )}
      {isLoading === true && <LoaderSpinner />}
      <div
        className="pagination-container"
        style={{
          display: 'flex',
          justifyContent: 'space-between'
        }}
      >
        <ul className="pagination">
          {pageNumbers.map((pageNumber, index) => (
            <li
              key={index}
              className={`page-item ${
                pageNumber === currentPage ? 'active' : ''
              }`}
            >
              {typeof pageNumber === 'number' ? (
                <a
                  className="page-link ps-2 pe-2 pt-1 pb-1"
                  onClick={() => handlePageChange(pageNumber)}
                  style={{ cursor: 'pointer' }}
                >
                  {pageNumber}
                </a>
              ) : (
                <span
                  className="page-link ps-2 pe-2 pt-1 pb-1"
                  style={{ cursor: 'default' }}
                >
                  {pageNumber}
                </span>
              )}
            </li>
          ))}
        </ul>
        {paginatedData?.length > 0 && (
          <div style={{ margin: '5px' }}>
            Mostrando {currentPage * paginationNumber - paginationNumber + 1} a{' '}
            {Math.min(currentPage * paginationNumber, filteredData.length)} de{' '}
            {filteredData.length}
          </div>
        )}
      </div>
    </div>
  );
};
BaseTable.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  paginationNumber: PropTypes.number,
  searchBarPlaceHolder: PropTypes.string,
  searchBarWidth: PropTypes.oneOf(['20%', '30%', '40%', '50%']),
  errorNoDataToShow: PropTypes.string,
  onViewItem: PropTypes.func,
  onEditItem: PropTypes.func,
  onDeleteItem: PropTypes.func,
  onRowClick: PropTypes.func,
  onExportPdf: PropTypes.func,
  isLoading: PropTypes.bool.isRequired
};
BaseTable.defaultProps = {
  paginationNumber: 10,
  searchBarPlaceHolder: 'Buscar...',
  searchBarWidth: '20%',
  errorNoDataToShow: 'No hay información disponible.',
  isLoading: true
};

export default BaseTable;
