import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import avatarImage from 'assets/img/team/avatar.png';
import Avatar from 'components/common/Avatar';
import AppContext, { AuthContext } from 'context/Context';

const ProfileDropdown = () => {
  const {
    config: { isDark },
    setConfig
  } = useContext(AppContext);
  const { logout } = useContext(AuthContext);

  const onClickLogOut = () => {
    //TODO: call logout API
    logout();
  };

  return (
    <Dropdown navbar={true} as="li">
      <Dropdown.Toggle
        bsPrefix="toggle"
        as={Link}
        to="#!"
        className="pe-0 ps-2 nav-link"
      >
        <Avatar src={avatarImage} />
      </Dropdown.Toggle>

      <Dropdown.Menu className="dropdown-caret dropdown-menu-card  dropdown-menu-end">
        <div className="bg-white rounded-2 py-2 dark__bg-1000">
          <Dropdown.Item as={Link} to="/">
            <FontAwesomeIcon icon="user" className="me-2 " />
            <span>Perfil</span>
          </Dropdown.Item>
          <Dropdown.Item as={Link} onClick={() => setConfig('isDark', !isDark)}>
            <FontAwesomeIcon icon={isDark ? 'sun' : 'moon'} className="me-2 " />
            {isDark ? 'Tema claro' : 'Tema oscuro'}
          </Dropdown.Item>
          <Dropdown.Item
            as={Link}
            onClick={onClickLogOut}
            to="/authentication/login"
          >
            <FontAwesomeIcon icon="sign-out-alt" className="me-2 " />
            Cerrar sesión
          </Dropdown.Item>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ProfileDropdown;
