import { useApiGet } from 'hooks/useApi';
import React, { useEffect, useState } from 'react';
import { ItHasValue } from 'utils/ObjectUtils';
import MainContainer from '../common/MainContainer';
import CustomerTableWrapper from './CustomerTableWrapper';
import LoaderSpinner from '../common/LoaderSpinner';
import ConfirmationModal from '../common/ConfirmationModal';

const titlePage = 'Clientes';
const SearchCustomer = () => {
  const allCustomerGet = useApiGet(`api/v1/branch/allBranches`);

  const [tableData, setTableData] = useState([]);
  const [modalConfig, setModalConfig] = useState({
    isModalOpen: false,
    title: '',
    description: ''
  });

  useEffect(() => {
    allCustomerGet.dispatchGet();
  }, []);

  useEffect(() => {
    if (ItHasValue(allCustomerGet.response)) {
      setTableData(allCustomerGet.response);
    }
  }, [allCustomerGet.response]);

  const onViewCustomer = customerId => {
    //TODO: add link to view branch if the user has permissions
    console.log({ customerId });
  };
  const onEditCustomer = customerId => {
    //TODO: add link to edit branch if the user has permissions
    console.log({ customerId });
  };
  const onDeleteCustomer = (customerId, customerName) => {
    setModalConfig({
      isModalOpen: true,
      title: 'Eliminar cliente',
      description: `¿Deseas eliminar el cliente '${customerName}' de forma permanente?`
    });
    console.log({ customerId, customerName });
  };

  return (
    <>
      <MainContainer titlePage={titlePage}>
        {tableData.length > 0 ? (
          <CustomerTableWrapper
            tableData={tableData}
            onViewCustomer={onViewCustomer}
            onEditCustomer={onEditCustomer}
            onDeleteCustomer={onDeleteCustomer}
          />
        ) : (
          <LoaderSpinner />
        )}
      </MainContainer>
      <ConfirmationModal
        isOpen={modalConfig.isModalOpen}
        title={modalConfig.title}
        description={modalConfig.description}
        onAcceptEvent={() => {
          setModalConfig({
            ...modalConfig,
            isModalOpen: false
          });
        }}
        onCancelEvent={() => {
          setModalConfig({
            ...modalConfig,
            isModalOpen: false
          });
        }}
      />
    </>
  );
};

export default SearchCustomer;
