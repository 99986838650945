const ERROR_UNAUTHORIZED_STATUS_CODE = 498;
const ERROR_EXPIRED_STATUS_CODE = 499;

export const isInvalidSession = statusCode => {
  // TODO: Is this necessary?
  const unauthorizedStatusCodes = [
    ERROR_UNAUTHORIZED_STATUS_CODE,
    ERROR_EXPIRED_STATUS_CODE
  ];
  return unauthorizedStatusCodes.includes(statusCode);
};

export const isAPICallSuccessful = request => {
  return request.response && request.isLoading === false;
};
