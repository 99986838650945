import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { Navigate } from 'react-router-dom';
import { AuthContext } from 'context/Context';
import { useApiPost } from 'hooks/useApi';
import { ItHasValue } from 'utils/ObjectUtils';

const LoginForm = ({ hasLabel }) => {
  const { login, isAuthenticated } = useContext(AuthContext);

  const [formData, setFormData] = useState({
    email: '',
    password: ''
  });

  const loginRequest = useApiPost(`api/v1/session/login`, formData);

  const handleSubmit = e => {
    e.preventDefault();
    loginRequest.dispatchPost();
  };

  useEffect(() => {
    if (ItHasValue(loginRequest.response)) {
      validateLoginResponse(loginRequest.response);
    }
  }, [loginRequest.response]);

  const validateLoginResponse = response => {
    if (response.success) {
      toast.success(`Bienvenido ${response.employeeName}`, {
        theme: 'colored'
      });
      login(response.token);
    }
    if (!response.success) {
      toast.error(response.message, {
        theme: 'colored'
      });
    }
  };

  useEffect(() => {
    console.log(formData);
  }, [formData]);

  useEffect(() => {
    if (ItHasValue(loginRequest.errorMessages)) {
      console.log({ errorMessages: loginRequest.errorMessages });
    }
  }, [loginRequest.errorMessages]);

  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  return (
    <>
      {isAuthenticated() ? (
        <Navigate replace to="/" />
      ) : (
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            {hasLabel && <Form.Label>Correo</Form.Label>}
            <Form.Control
              placeholder={!hasLabel ? 'Correo' : ''}
              value={formData.email}
              name="email"
              onChange={handleFieldChange}
              type="email"
            />
          </Form.Group>

          <Form.Group className="mb-3">
            {hasLabel && <Form.Label>Contraseña</Form.Label>}
            <Form.Control
              placeholder={!hasLabel ? 'Contraseña' : ''}
              value={formData.password}
              name="password"
              onChange={handleFieldChange}
              type="password"
            />
          </Form.Group>

          <Row className="justify-content-between align-items-center">
            <Col xs="auto"></Col>

            <Col xs="auto">
              <p
                className="fs--1 mb-0"
                // to={`/authentication/${layout}/forgot-password`}
              >
                Forgot Password?
              </p>
            </Col>
          </Row>

          <Form.Group>
            <Button
              type="submit"
              color="primary"
              className="mt-3 w-100"
              disabled={!formData.email || !formData.password}
            >
              Iniciar sesión
            </Button>
          </Form.Group>
        </Form>
      )}
    </>
  );
};

LoginForm.propTypes = {
  hasLabel: PropTypes.bool
};

LoginForm.defaultProps = {
  hasLabel: false
};

export default LoginForm;
