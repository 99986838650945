import { useContext, useState } from 'react';
import axios from 'axios';
import { AuthContext } from 'context/Context';

// const devBaseURL = 'http://127.0.0.1:8000/';
const prodBaseURL = 'https://elchichojr.online/';
const httpStatusCode_400 = 400;
const httpStatusCode_500 = 500;
//const errorLoginExpiredStatusCode = 499;

axios.defaults.baseURL = prodBaseURL;

const getHeaders = () => {
  const { token } = useContext(AuthContext);

  return {
    Authorization: 'Bearer your_access_token',
    CustomHeader: 'CustomValue',
    'jr-auth': token
  };
};

export const useApiGet = url => {
  const headers = getHeaders();
  return AxiosGet(url, headers);
};

const AxiosGet = (url, headers = {}) => {
  const [response, setResponse] = useState(undefined);
  const [error, setError] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);

  const axiosParams = {
    method: 'GET',
    url: url,
    headers: {
      accept: '*/*',
      ...headers
    },
    data: {}
  };

  const fetchData = async params => {
    try {
      const result = await axios.request(params);
      setResponse(result.data);
    } catch (error) {
      // if (error.response.data.statusCode === errorLoginExpiredStatusCode) {
      //TODO: add navigation to login for expired session
      // }
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const dispatchGet = () => {
    fetchData(axiosParams);
  };

  return {
    response,
    error,
    isLoading,
    dispatchGet
  };
};

export const useApiPost = (url, data) => {
  const headers = getHeaders();
  return AxiosPost(url, data, headers);
};

export const AxiosPost = (url, data = {}, headers = {}) => {
  const [response, setResponse] = useState(undefined);
  const [errorMessages, setErrorMessages] = useState(undefined);
  const [error, setError] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);

  const axiosParams = {
    method: 'POST',
    url: url,
    headers: {
      accept: '*/*',
      ...headers
    },
    data: data
  };

  const sendData = async params => {
    try {
      const result = await axios.request(params);
      setResponse(result.data);
    } catch (error) {
      if (error.response.status === httpStatusCode_400) {
        setErrorMessages(error.response.data);
      }
      if (error.response.status === httpStatusCode_500) {
        setError(error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const dispatchPost = () => {
    sendData(axiosParams);
  };

  return {
    response,
    errorMessages,
    error,
    isLoading,
    dispatchPost
  };
};
