import React from 'react';
import CardDropdown from 'components/common/CardDropdown';
import DropdownItem from 'react-bootstrap/esm/DropdownItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { Dropdown } from 'react-bootstrap';

const RowActionsDropdown = ({
  itemId,
  itemName,
  onViewItem,
  onEditItem,
  onDeleteItem
}) => {
  return (
    <CardDropdown>
      <div className="py-2">
        <DropdownItem onClick={() => onViewItem(itemId)}>
          <FontAwesomeIcon icon="eye" className="fs--2" /> Ver
        </DropdownItem>
        <DropdownItem onClick={() => onEditItem(itemId)}>
          {' '}
          <FontAwesomeIcon icon="pencil-alt" className="fs--2" /> Editar
        </DropdownItem>
        <Dropdown.Divider />
        <DropdownItem
          className="text-danger"
          onClick={() => onDeleteItem(itemId, itemName)}
        >
          <FontAwesomeIcon icon="trash" className="fs--2" /> Eliminar
        </DropdownItem>
      </div>
    </CardDropdown>
  );
};
RowActionsDropdown.propTypes = {
  itemId: PropTypes.number.isRequired,
  itemName: PropTypes.string,
  onViewItem: PropTypes.func,
  onEditItem: PropTypes.func,
  onDeleteItem: PropTypes.func
};

RowActionsDropdown.defaultProps = {
  itemId: 0,
  itemName: '',
  onViewItem: () => {},
  onEditItem: () => {},
  onDeleteItem: () => {}
};

export default RowActionsDropdown;
