import SoftBadge from 'components/common/SoftBadge';
import PropTypes from 'prop-types';
import React from 'react';

const PillBadgeCell = ({ text }) => {
  const getBGColor = () => {
    switch (text) {
      case 'Bueno':
        return 'success';
      case 'Regular':
        return 'warning';
      case 'Malo':
        return 'danger';
      default:
        return 'primary';
    }
  };

  return (
    <SoftBadge pill bg={getBGColor()} className="me-2">
      {text}
    </SoftBadge>
  );
};

PillBadgeCell.propTypes = {
  text: PropTypes.string.isRequired
};
PillBadgeCell.defaultProps = {};

export default PillBadgeCell;
