import React, { useEffect, useState } from 'react';
import MainContainer from '../../common/MainContainer';
import { useApiGet } from 'hooks/useApi';
import { isAPICallSuccessful } from 'components/utilities/ApiUtils';
import SubtitleWithButton from '../../common/SubtitleWithButton';
import {
  addSaleDetailSecondStep,
  selectClientVehiclesFirstStep,
  titlePage
} from './NewSaleBodyWrapperUtils';
import SelectClientAndVehicle from './SelectClientAndVehicle';
import NewSaleDetails from './NewSaleDetails';

const NewSaleBodyWrapper = () => {
  const [saleData, setSaleData] = useState({
    vehiclesDataList: [],
    clientData: null,
    saleDetails: {
      dateTime: new Date()
    }
  });

  const allClientsForNewSaleGet = useApiGet(
    `api/v1/client/allClientsAndVehiclesForNewSale`
  );

  const [newSaleConfig, setNewSaleConfig] = useState({
    clientsTable: {
      columns: [],
      data: []
    },
    vehiclesTable: {
      columns: [],
      data: []
    },
    isLoading: true,
    newSaleBodyRender: selectClientVehiclesFirstStep
  });

  useEffect(() => {
    allClientsForNewSaleGet.dispatchGet();
  }, []);

  useEffect(() => {
    if (isAPICallSuccessful(allClientsForNewSaleGet)) {
      setNewSaleConfig({
        ...newSaleConfig,
        clientsTable: allClientsForNewSaleGet.response.clientsTable,
        vehiclesTable: allClientsForNewSaleGet.response.vehiclesTable,
        isLoading: false
      });
    }
  }, [allClientsForNewSaleGet.response]);

  const renderNewSaleBody = () => {
    if (newSaleConfig.newSaleBodyRender === selectClientVehiclesFirstStep) {
      return (
        <SelectClientAndVehicle
          saleData={saleData}
          setSaleData={setSaleData}
          newSaleConfig={newSaleConfig}
        />
      );
    } else {
      return <NewSaleDetails saleData={saleData} setSaleData={setSaleData} />;
    }
  };

  useEffect(() => {
    console.log(saleData);
  }, [saleData]);

  const changeToNextStepOfTheSale = () => {
    if (newSaleConfig.newSaleBodyRender === selectClientVehiclesFirstStep) {
      setNewSaleConfig({
        ...newSaleConfig,
        newSaleBodyRender: addSaleDetailSecondStep
      });
    }
  };
  const changeToPreviousStepOfTheSale = () => {
    if (newSaleConfig.newSaleBodyRender === addSaleDetailSecondStep) {
      setNewSaleConfig({
        ...newSaleConfig,
        newSaleBodyRender: selectClientVehiclesFirstStep
      });
    }
  };

  return (
    <MainContainer titlePage={titlePage} pageHeight="600px">
      {renderNewSaleBody()}
      <SubtitleWithButton
        text={''}
        isFirstButtonHidden={
          saleData?.vehiclesDataList?.length === 0 ||
          saleData.clientData === null
        }
        isPrimary
        firstButtonDisable={false}
        firstButtonText={'Continuar'}
        firstButtonOnClick={() => {
          changeToNextStepOfTheSale();
        }}
        isSecondButtonHidden={
          newSaleConfig.newSaleBodyRender === selectClientVehiclesFirstStep
        }
        secondButtonDisable={false}
        secondButtonText={'Regresar'}
        secondButtonOnClick={() => {
          changeToPreviousStepOfTheSale();
        }}
      />
    </MainContainer>
  );
};

export default NewSaleBodyWrapper;
