import {
  formatStringNumberPhone,
  formatStringToCamelCase,
  truncateString
} from 'components/utilities/StringUtil';
import React from 'react';
import RowActionsDropdown from '../common/RowActionsDropdown';
import PropTypes from 'prop-types';

const CustomerTableWrapper = ({
  tableData,
  onViewCustomer,
  onEditCustomer,
  onDeleteCustomer
}) => {
  return (
    <>
      <div className="table-responsive scrollbar">
        <table className="table overflow-hidden table-sm ">
          <thead className="table-primary">
            <tr>
              <th scope="col">Nombre</th>
              <th scope="col">Dirección</th>
              <th scope="col">Administrador</th>
              <th scope="col">Celular</th>
              <th className="text-end" scope="col">
                Acciones
              </th>
            </tr>
          </thead>
          <tbody>
            {tableData?.map(item => (
              <tr className="align-middle" key={item.branchId}>
                <td className="text-nowrap">
                  {truncateString(formatStringToCamelCase(item.name), 30)}
                </td>
                <td className="text-nowrap">
                  {truncateString(formatStringToCamelCase(item.address), 30)}
                </td>
                <td className="text-nowrap">
                  {formatStringToCamelCase(item.managerName)}
                </td>
                <td className="text-wrap">
                  {formatStringNumberPhone(item.managerPhone)}
                </td>
                <td className="text-end">
                  <RowActionsDropdown
                    itemId={item.branchId}
                    itemName={formatStringToCamelCase(item.name)}
                    onViewItem={onViewCustomer}
                    onEditItem={onEditCustomer}
                    onDeleteItem={(branchId, branchName) => {
                      onDeleteCustomer(branchId, branchName);
                    }}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

CustomerTableWrapper.propTypes = {
  tableData: PropTypes.array.isRequired,
  onViewCustomer: PropTypes.func,
  onEditCustomer: PropTypes.func,
  onDeleteCustomer: PropTypes.func
};

CustomerTableWrapper.defaultProps = {
  tableData: [],
  onViewCustomer: () => {},
  onEditCustomer: () => {},
  onDeleteCustomer: () => {}
};
export default CustomerTableWrapper;
