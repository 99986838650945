import React from 'react';
import PropTypes from 'prop-types';
import PageTitleHeader from '../PageTitleHeader';
import FalconComponentCard from 'components/common/FalconComponentCard';

const MainContainer = ({ titlePage, children, pageHeight = '700px' }) => {
  return (
    <>
      <PageTitleHeader titlePage={titlePage} />
      <FalconComponentCard style={{ height: pageHeight }}>
        <FalconComponentCard.Body className="bg-white">
          {children}
        </FalconComponentCard.Body>
      </FalconComponentCard>
    </>
  );
};
MainContainer.propTypes = {
  titlePage: PropTypes.string.isRequired,
  pageHeight: PropTypes.string,
  children: PropTypes.node
};

export default MainContainer;
