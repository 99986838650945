import React from 'react';
import { Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { getReadOnlyInputStyles } from './InputUtils';

const InputPhone = ({
  id,
  name,
  label,
  value,
  error,
  onChange,
  placeholder,
  readOnly,
  col
}) => {
  const formatPhoneNumber = phoneValue => {
    const formattedValue = [];
    const totalDigitsPhoneNumber = 10;

    for (let i = 0; i < phoneValue.length; i++) {
      if (i < totalDigitsPhoneNumber) {
        if (i === 0) {
          formattedValue.push('(');
        }
        if (i === 3) {
          formattedValue.push(') ');
        }
        if (i === 6) {
          formattedValue.push(' ');
        }
        let numberByIndex = phoneValue[i];
        formattedValue.push(numberByIndex);
      }
    }
    return formattedValue.join('');
  };

  const handleInputChange = event => {
    const value = event.target.value;
    const onlyNumbers = value.replace(/\D/g, '');
    onChange?.(onlyNumbers);
  };

  return (
    <Form.Group className="mb-3" as={col} controlId={id}>
      <Form.Label>{label ?? ''}</Form.Label>
      <Form.Control
        type={'text'}
        name={name}
        placeholder={placeholder}
        isInvalid={error}
        value={formatPhoneNumber(value)}
        readOnly={readOnly}
        onChange={handleInputChange}
        style={getReadOnlyInputStyles(readOnly)}
      />
      <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
    </Form.Group>
  );
};
InputPhone.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  error: PropTypes.string,
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
  col: PropTypes.any
};

InputPhone.defaultProps = {
  id: '',
  name: '',
  label: '',
  value: '',
  readOnly: false,
  error: null,
  onChange: () => {}
};
export default InputPhone;
