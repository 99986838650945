import PropTypes from 'prop-types';
import React from 'react';

const TextCell = ({ text }) => {
  return <span>{text}</span>;
};

TextCell.propTypes = {
  text: PropTypes.string
};
TextCell.defaultProps = {};

export default TextCell;
