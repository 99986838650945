import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';

const EmptyListOfItemsContainer = ({ text }) => {
  return (
    <Row
      className="me-0 ms-0 mt-3 mb-3 pt-1 align-items-center"
      style={{
        minHeight: '90px',
        border: '1px dashed #ddd',
        borderRadius: '10px'
      }}
    >
      <Col className="text-center text-muted">
        <p>{text}</p>
      </Col>
    </Row>
  );
};
EmptyListOfItemsContainer.propTypes = {
  text: PropTypes.string.isRequired
};

EmptyListOfItemsContainer.defaultProps = {
  text: ''
};
export default EmptyListOfItemsContainer;
