import BaseTable from 'components/el-chicho-jr/common/table/BaseTable';
import React from 'react';
import PropTypes from 'prop-types';

const AddVehiclesModalContent = ({
  onSelectedVehicle,
  allVehiclesForNewSaleData,
  isLoading
}) => {
  return (
    <BaseTable
      columns={allVehiclesForNewSaleData?.columns ?? []}
      data={allVehiclesForNewSaleData?.data ?? []}
      searchBarWidth={'40%'}
      onRowClick={item => onSelectedVehicle?.(item)}
      isLoading={isLoading}
    />
  );
};
AddVehiclesModalContent.propTypes = {
  onSelectedVehicle: PropTypes.func,
  allVehiclesForNewSaleData: PropTypes.object,
  isLoading: PropTypes.bool
};

AddVehiclesModalContent.defaultProps = {};

export default AddVehiclesModalContent;
