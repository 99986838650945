import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import PropTypes from 'prop-types';

const Select = ({ id, name, label, value, options, error, onChange, col }) => {
  const [selectValue, setSelectValue] = useState(value);

  const handleInputChange = event => {
    const newValue = event.target.value;
    setSelectValue(newValue);
    onChange?.(newValue);
  };
  return (
    <Form.Group className="mb-3" as={col} controlId={id}>
      <Form.Label>{label ?? ''}</Form.Label>
      <Form.Select
        name={name}
        aria-label={label}
        value={selectValue}
        onChange={handleInputChange}
        isInvalid={error}
      >
        {options.map(option => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </Form.Select>
      <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
    </Form.Group>
  );
};
Select.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  options: PropTypes.array,
  error: PropTypes.string,
  onChange: PropTypes.func,
  col: PropTypes.any
};

Select.defaultProps = {
  id: '',
  name: '',
  label: '',
  value: '',
  options: [],
  type: 'text',
  error: null,
  onChange: () => {}
};
export default Select;
