import ProfileDropdown from 'components/navbar/top/ProfileDropdown';
import React from 'react';
import { Nav } from 'react-bootstrap';

const TopNavRightSideNavItem = () => {
  // const {
  //   config: { isDark, isRTL },
  //   setConfig
  // } = useContext(AppContext);
  return (
    <Nav
      navbar
      className="navbar-nav-icons ms-auto flex-row align-items-center"
      as="ul"
    >
      {/* <CartNotification /> */}
      {/*<NotificationDropdown /> // TODO; add the custom notifications*/}
      {/* <NineDotMenu /> */}
      <ProfileDropdown />
    </Nav>
  );
};

export default TopNavRightSideNavItem;
