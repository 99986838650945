import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import MainContainer from '../common/MainContainer';
import Input from '../common/input/Input';
import Select from '../common/Select';
import FalconComponentCard from 'components/common/FalconComponentCard';
import Divider from 'components/common/Divider';

const titlePage = 'Nuevo vehículo';
const NewVehicle = () => {
  // const [formData, setData] = useState({});

  // const onSubmit = data => {
  //   setData(data);
  // };

  return (
    <MainContainer titlePage={titlePage}>
      <Row>
        <Col md={12}>
          <Input
            name="vehicleType"
            id="vehicleType"
            label="Tipo de vehículo"
            value=""
            error=""
            placeholder="Ingresa el tipo de vehículo"
            type="text"
            onChange={value => {
              console.log(value);
            }}
            maxLength={25}
          />
        </Col>
      </Row>
      <Row className="mb-3 g-3">
        <Input
          name="brand"
          id="vehicleBrand"
          label="Marca"
          value=""
          error=""
          placeholder="Ingresa la marca del vehículo"
          type="text"
          onChange={value => {
            console.log(value);
          }}
          maxLength={25}
          col={Col}
        />
        <Input
          name="line"
          id="vehicleLine"
          label="Linea"
          value=""
          error=""
          placeholder="Ingresa la linea de vehículo"
          type="text"
          onChange={value => {
            console.log(value);
          }}
          maxLength={25}
          col={Col}
        />
        <Input
          name="model"
          id="vehicleModel"
          label="Modelo"
          value=""
          error=""
          placeholder="Ingresa el modelo"
          type="text"
          onChange={value => {
            console.log(value);
          }}
          maxLength={25}
          col={Col}
        />
      </Row>
      <Row className="mb-3 g-3">
        <Input
          name="colorColor"
          label="Color"
          value=""
          error=""
          placeholder="Ingresa el color"
          type="text"
          onChange={value => {
            console.log(value);
          }}
          maxLength={25}
          col={Col}
        />
        <Input
          name="serie"
          label="Serie"
          value=""
          error=""
          placeholder="Ingresa el número de serie"
          type="text"
          onChange={value => {
            console.log(value);
          }}
          maxLength={25}
          col={Col}
        />
        <Select
          name="origin"
          label="Origen"
          value={''}
          error=""
          options={[
            { value: 'NACIONAL', label: 'Nacional' },
            { value: 'IMPORTADO', label: 'Importado' },
            { value: 'NACIONALIZADO', label: 'Nacionalizado' }
          ]}
          type="text"
          onChange={value => {
            console.log(value);
          }}
          col={Col}
        />
      </Row>
      <Row className="mb-5 g-3">
        <Input
          name="characteristics"
          label="Caracteristicas"
          value=""
          error=""
          placeholder="Ingresa caracteristicas del vehículo"
          onChange={value => {
            console.log(value);
          }}
          maxLength={25}
          col={Col}
        />
      </Row>
      <Divider className="mt-5 mb-5 bg-transparent">
        <FalconComponentCard.Header
          title="Datos de venta"
          className="bg-transparent"
          noPreview
        />
      </Divider>

      <Row className="mb-3 g-3">
        <Input
          name="cashPrice"
          id="vehicleCashPrice"
          label="Precio contado"
          value=""
          error=""
          placeholder="Ingresa el precio de contado"
          type="text"
          onChange={value => {
            console.log(value);
          }}
          maxLength={25}
          col={Col}
        />
        <Input
          name="creditPrice"
          id="vehicleCreditPrice"
          label="Precio credito"
          value=""
          error=""
          placeholder="Ingresa el precio de credito"
          type="text"
          onChange={value => {
            console.log(value);
          }}
          maxLength={25}
          col={Col}
        />
        <Select //TODO: get options value from database
          name="branch"
          label="Sucursal"
          value={''}
          error=""
          options={[
            { value: '1', label: 'La Paz' },
            { value: '2', label: 'AUTOS EL CHICHO JR LOS CABOS' }
          ]}
          placeholder="Ingresa el origen"
          onChange={value => {
            console.log(value);
          }}
          col={Col}
        />
      </Row>
      <Row>
        <Col className="text-end">
          <Button variant="secondary" type="submit" disabled>
            Guardar nuevo vehículo
          </Button>
        </Col>
      </Row>
    </MainContainer>
  );
  // return <DefaultPageTemplate titlePage={titlePage} />;
};

export default NewVehicle;
