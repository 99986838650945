import { AuthContext } from 'context/Context';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useContext } from 'react';

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(localStorage.getItem('jwtToken'));

  const login = token => {
    setToken(token);
    localStorage.setItem('jwtToken', token);
  };

  const logout = () => {
    setToken(null);
    localStorage.removeItem('jwtToken');
  };

  const isAuthenticated = () => !!token;

  return (
    <AuthContext.Provider value={{ token, login, logout, isAuthenticated }}>
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = { children: PropTypes.node };
