import React from 'react';
import BaseTable from './common/table/BaseTable';
import PageHeader from 'components/common/PageHeader';
import FalconComponentCard from 'components/common/FalconComponentCard';

const columns = ['id', 'name', 'position', 'salary'];
const data = [
  { id: 1, name: 'John Doe', position: 'Software Engineer', salary: 80000 },
  { id: 2, name: 'Jane Smith', position: 'Product Manager', salary: 90000 },
  { id: 3, name: 'Bob Johnson', position: 'QA Tester', salary: 75000 },
  { id: 4, name: 'Janet Jackson', position: 'UX Designer', salary: 60000 },
  {
    id: 5,
    name: 'Judy Anderson',
    position: 'Frontend Developer',
    salary: 65000
  },
  {
    id: 6,
    name: 'Timothy Jones',
    position: 'Backend Developer',
    salary: 70000
  },
  { id: 7, name: 'Helen Johnson', position: 'Graphic Designer', salary: 60000 },
  { id: 8, name: 'Joe Smith', position: 'Database Admin', salary: 75000 },
  { id: 9, name: 'Jane Doe', position: 'System Admin', salary: 80000 },
  { id: 10, name: 'John Smith', position: 'Software Engineer', salary: 90000 },
  { id: 11, name: 'Jane Smith', position: 'Product Manager', salary: 100000 },
  { id: 12, name: 'Bob Johnson', position: 'QA Tester', salary: 75000 },
  { id: 13, name: 'Janet Jackson', position: 'UX Designer', salary: 60000 },
  {
    id: 14,
    name: 'Judy Anderson',
    position: 'Frontend Developer',
    salary: 65000
  },
  {
    id: 15,
    name: 'Timothy Jones',
    position: 'Backend Developer',
    salary: 70000
  },
  {
    id: 16,
    name: 'Helen Johnson',
    position: 'Graphic Designer',
    salary: 60000
  },
  { id: 17, name: 'Joe Smith', position: 'Database Admin', salary: 75000 },
  { id: 18, name: 'Jane Doe', position: 'System Admin', salary: 80000 },
  { id: 19, name: 'John Smith', position: 'Software Engineer', salary: 90000 },
  { id: 20, name: 'Jane Smith', position: 'Product Manager', salary: 100000 },
  { id: 21, name: 'Bob Johnson', position: 'QA Tester', salary: 75000 },
  { id: 22, name: 'Janet Jackson', position: 'UX Designer', salary: 60000 },
  {
    id: 23,
    name: 'Judy Anderson',
    position: 'Frontend Developer',
    salary: 65000
  },
  {
    id: 24,
    name: 'Timothy Jones',
    position: 'Backend Developer',
    salary: 70000
  },
  {
    id: 25,
    name: 'Helen Johnson',
    position: 'Graphic Designer',
    salary: 60000
  },
  { id: 26, name: 'Joe Smith', position: 'Database Admin', salary: 75000 },
  { id: 27, name: 'Jane Doe', position: 'System Admin', salary: 80000 },
  { id: 28, name: 'John Smith', position: 'Software Engineer', salary: 90000 },
  { id: 29, name: 'Jane Smith', position: 'Product Manager', salary: 100000 },
  { id: 30, name: 'Bob Johnson', position: 'QA Tester', salary: 75000 },
  { id: 31, name: 'Janet Jackson', position: 'UX Designer', salary: 60000 },
  {
    id: 32,
    name: 'Judy Anderson',
    position: 'Frontend Developer',
    salary: 65000
  },
  {
    id: 33,
    name: 'Timothy Jones',
    position: 'Backend Developer',
    salary: 70000
  },
  {
    id: 34,
    name: 'Helen Johnson',
    position: 'Graphic Designer',
    salary: 60000
  },
  { id: 35, name: 'Joe Smith', position: 'Database Admin', salary: 75000 },
  { id: 36, name: 'Jane Doe', position: 'System Admin', salary: 80000 },
  { id: 37, name: 'John Smith', position: 'Software Engineer', salary: 90000 },
  { id: 38, name: 'Jane Smith', position: 'Product Manager', salary: 100000 },
  { id: 39, name: 'Bob Johnson', position: 'QA Tester', salary: 75000 },
  { id: 40, name: 'Janet Jackson', position: 'UX Designer', salary: 60000 },
  {
    id: 41,
    name: 'Judy Anderson',
    position: 'Frontend Developer',
    salary: 65000
  },
  {
    id: 42,
    name: 'Timothy Jones',
    position: 'Backend Developer',
    salary: 70000
  },
  {
    id: 43,
    name: 'Helen Johnson',
    position: 'Graphic Designer',
    salary: 60000
  },
  { id: 44, name: 'Joe Smith', position: 'Database Admin', salary: 75000 },
  { id: 45, name: 'Jane Doe', position: 'System Admin', salary: 80000 },
  { id: 46, name: 'John Smith', position: 'Software Engineer', salary: 90000 },
  { id: 47, name: 'Jane Smith', position: 'Product Manager', salary: 100000 },
  { id: 48, name: 'Bob Johnson', position: 'QA Tester', salary: 75000 },
  { id: 49, name: 'Janet Jackson', position: 'UX Designer', salary: 60000 },
  {
    id: 50,
    name: 'Judy Anderson',
    position: 'Frontend Developer',
    salary: 65000
  },
  {
    id: 51,
    name: 'Timothy Jones',
    position: 'Backend Developer',
    salary: 70000
  },
  {
    id: 52,
    name: 'Helen Johnson',
    position: 'Graphic Designer',
    salary: 60000
  },
  { id: 53, name: 'Joe Smith', position: 'Database Admin', salary: 75000 },
  { id: 54, name: 'Jane Doe', position: 'System Admin', salary: 80000 },
  { id: 55, name: 'John Smith', position: 'Software Engineer', salary: 90000 },
  { id: 56, name: 'Jane Smith', position: 'Product Manager', salary: 100000 },
  { id: 57, name: 'Bob Johnson', position: 'QA Tester', salary: 75000 },
  { id: 58, name: 'Janet Jackson', position: 'UX Designer', salary: 60000 },
  {
    id: 59,
    name: 'Judy Anderson',
    position: 'Frontend Developer',
    salary: 65000
  },
  {
    id: 60,
    name: 'Timothy Jones',
    position: 'Backend Developer',
    salary: 70000
  },
  {
    id: 61,
    name: 'Helen Johnson',
    position: 'Graphic Designer',
    salary: 60000
  },
  { id: 62, name: 'Joe Smith', position: 'Database Admin', salary: 75000 },
  { id: 63, name: 'Jane Doe', position: 'System Admin', salary: 80000 },
  { id: 64, name: 'John Smith', position: 'Software Engineer', salary: 90000 },
  { id: 65, name: 'Jane Smith', position: 'Product Manager', salary: 100000 },
  { id: 66, name: 'Bob Johnson', position: 'QA Tester', salary: 75000 },
  { id: 67, name: 'Janet Jackson', position: 'UX Designer', salary: 60000 },
  {
    id: 68,
    name: 'Judy Anderson',
    position: 'Frontend Developer',
    salary: 65000
  },
  {
    id: 69,
    name: 'Timothy Jones',
    position: 'Backend Developer',
    salary: 70000
  },
  {
    id: 70,
    name: 'Helen Johnson',
    position: 'Graphic Designer',
    salary: 60000
  },
  { id: 71, name: 'Joe Smith', position: 'Database Admin', salary: 75000 },
  { id: 72, name: 'Jane Doe', position: 'System Admin', salary: 80000 },
  { id: 73, name: 'John Smith', position: 'Software Engineer', salary: 90000 },
  { id: 74, name: 'Jane Smith', position: 'Product Manager', salary: 100000 },
  { id: 75, name: 'Bob Johnson', position: 'QA Tester', salary: 75000 },
  { id: 76, name: 'Janet Jackson', position: 'UX Designer', salary: 60000 }
];

const Sandbox = () => {
  return (
    <>
      <PageHeader title={'Sandbox'} className="mb-3"></PageHeader>
      <FalconComponentCard>
        <FalconComponentCard.Body>
          <BaseTable
            columns={columns}
            data={data}
            onExportPdf={item => console.log(item)}
          />
        </FalconComponentCard.Body>
      </FalconComponentCard>
    </>
  );
};

export default Sandbox;
