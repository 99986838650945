import React from 'react';
import MainContainer from '../common/MainContainer';
import { Button, Col, Row } from 'react-bootstrap';
import Select from '../common/Select';
import TextDivider from '../common/TextDivider';
import Input from '../common/input/Input';

const titlePage = 'Nuevo empleado';
const NewEmployee = () => {
  return (
    <MainContainer titlePage={titlePage} pageHeight="600px">
      <Row className="mb-3 g-3">
        <Input
          name="employeeName"
          id="employeeName"
          label="Nombre"
          value=""
          error=""
          placeholder="Ingresa nombre de empleado"
          type="text"
          onChange={value => {
            console.log(value);
          }}
          maxLength={25}
          col={Col}
        />
        <Input
          name="lastName"
          id="lastName"
          label="Apellido paterno"
          value=""
          error=""
          placeholder="Ingresa apellido paterno"
          type="text"
          onChange={value => {
            console.log(value);
          }}
          maxLength={25}
          col={Col}
        />
        <Input
          name="middleName"
          id="middleName"
          label="Apellido materno"
          value=""
          error=""
          placeholder="Ingresa apellido materno"
          type="text"
          onChange={value => {
            console.log(value);
          }}
          maxLength={25}
          col={Col}
        />
      </Row>
      <Row className="mb-5 g-3">
        <Input
          name="celPhone"
          label="Celular"
          value=""
          error=""
          placeholder="Ingresa el celular"
          type="text"
          onChange={value => {
            console.log(value);
          }}
          maxLength={25}
          col={Col}
        />

        <Select
          name="branch"
          label="Sucursal"
          value={''}
          error=""
          options={[
            { value: '1', label: 'La Paz' },
            { value: '2', label: 'AUTOS EL CHICHO JR LOS CABOS' }
          ]}
          type="text"
          onChange={value => {
            console.log(value);
          }}
          col={Col}
        />
      </Row>

      <TextDivider title={'Datos de inicio de sesión'} align={'cemter'} />

      <Row className="mb-3 mt-3 g-3">
        <Input
          name="email"
          id="employeeEmail"
          label="Correo electrónico"
          value=""
          error=""
          placeholder="Ingresa el correo electrónico"
          type="text"
          onChange={value => {
            console.log(value);
          }}
          maxLength={25}
          col={Col}
        />
        <Input
          name="password"
          id="sessionPassword"
          label="Contraseña"
          value=""
          error=""
          placeholder="Ingresa contraseña"
          type="text"
          onChange={value => {
            console.log(value);
          }}
          maxLength={25}
          col={Col}
        />
        <Input
          name="passwordConfirmation"
          id="sessionPasswordConfirmation"
          label="Confirmar contraseña"
          value=""
          error=""
          placeholder="Ingresa contraseña nuevamente"
          type="text"
          onChange={value => {
            console.log(value);
          }}
          maxLength={25}
          col={Col}
        />
      </Row>
      <Row>
        <Col className="text-end">
          <Button variant="secondary" type="submit" disabled>
            Guardar nuevo empleado
          </Button>
        </Col>
      </Row>
    </MainContainer>
  );
};

export default NewEmployee;
