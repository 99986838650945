import React, { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';

const TextDivider = ({ title, align }) => {
  useEffect(() => {}, [align]);

  return (
    <Row className="ms-2 me-2 navbar-vertical-label-wrapper">
      <Col className="ps-1 firstLine">
        <hr className="navbar-vertical-divider"></hr>
      </Col>
      <Col xs="auto" className="navbar-vertical-label navbar-vertical-label">
        <h5>{title}</h5>
      </Col>
      <Col className="ps-3 secondLine">
        <hr className="navbar-vertical-divider"></hr>
      </Col>
    </Row>
  );
};
TextDivider.propTypes = {
  title: PropTypes.string.isRequired,
  align: PropTypes.string
};

TextDivider.defaultProps = {
  title: '',
  align: 'center'
};

export default TextDivider;
