import React, { useEffect, useState } from 'react';
import { useApiGet } from 'hooks/useApi';
import { ItHasValue } from 'utils/ObjectUtils';
import ConfirmationModal from '../common/ConfirmationModal';
import LoaderSpinner from '../common/LoaderSpinner';
import MainContainer from '../common/MainContainer';
import EmployeeTableWrapper from './EmployeeTableWrapper';

const titlePage = 'Empleados';
const SearchEmployee = () => {
  const allEmployeesGet = useApiGet(`api/v1/employee/allEmployees`);

  const [tableData, setTableData] = useState([]);
  const [modalConfig, setModalConfig] = useState({
    isModalOpen: false,
    title: '',
    description: ''
  });

  useEffect(() => {
    allEmployeesGet.dispatchGet();
  }, []);

  useEffect(() => {
    if (ItHasValue(allEmployeesGet.response)) {
      setTableData(allEmployeesGet.response);
    }
  }, [allEmployeesGet.response]);

  const onViewEmployee = employeeId => {
    //TODO: add link to view Employee if the user has permissions
    console.log({ employeeId });
  };
  const onEditEmployee = employeeId => {
    //TODO: add link to edit Employee if the user has permissions
    console.log({ employeeId });
  };
  const onDeleteEmployee = (employeeId, employeeName) => {
    setModalConfig({
      isModalOpen: true,
      title: 'Eliminar empleado',
      description: `¿Deseas eliminar al empleado '${employeeName}' de forma permanente?`
    });
    console.log({ employeeId, employeeName });
  };

  return (
    <>
      <MainContainer titlePage={titlePage}>
        {tableData.length > 0 ? (
          <EmployeeTableWrapper
            tableData={tableData}
            onViewEmployee={onViewEmployee}
            onEditEmployee={onEditEmployee}
            onDeleteEmployee={onDeleteEmployee}
          />
        ) : (
          <LoaderSpinner />
        )}
      </MainContainer>
      <ConfirmationModal
        isOpen={modalConfig.isModalOpen}
        title={modalConfig.title}
        description={modalConfig.description}
        onAcceptEvent={() => {
          setModalConfig({
            ...modalConfig,
            isModalOpen: false
          });
        }}
        onCancelEvent={() => {
          setModalConfig({
            ...modalConfig,
            isModalOpen: false
          });
        }}
      />
    </>
  );
};

export default SearchEmployee;
