import React from 'react';
import { Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { getReadOnlyInputStyles } from './input/InputUtils';

const Label = ({ id, name, text, col }) => {
  return (
    <Form.Group className="mb-3" as={col} controlId={id}>
      <Form.Control
        type={'text'}
        name={name}
        value={text}
        readOnly={true}
        style={getReadOnlyInputStyles(true)}
      />
    </Form.Group>
  );
};
Label.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  text: PropTypes.string,
  col: PropTypes.any
};

Label.defaultProps = {
  id: '',
  name: '',
  text: ''
};
export default Label;
