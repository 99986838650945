import React from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Row } from 'react-bootstrap';

const SubtitleWithButton = ({
  text,
  isFirstButtonHidden,
  isSecondButtonHidden,
  firstButtonText,
  secondButtonText,
  firstButtonDisable,
  secondButtonDisable,
  isPrimary,
  firstButtonOnClick,
  secondButtonOnClick
}) => {
  return (
    <Row>
      <Col
        xs="auto"
        className="navbar-vertical-label navbar-vertical-label pt-2"
      >
        <h5>{text}</h5>
      </Col>
      <Col className="text-end">
        {!isSecondButtonHidden && (
          <Button
            className={isPrimary ? 'btn-primary' : 'btn-outline-primary'}
            variant={isPrimary ? 'primary' : 'outline-primary'}
            type="submit"
            disabled={secondButtonDisable}
            onClick={secondButtonOnClick}
          >
            {secondButtonText}
          </Button>
        )}
        {!isFirstButtonHidden && (
          <Button
            className={
              isPrimary ? 'btn-primary ms-2' : 'btn-outline-primary ms-2'
            }
            variant={isPrimary ? 'primary' : 'outline-primary'}
            type="submit"
            disabled={firstButtonDisable}
            onClick={firstButtonOnClick}
          >
            {firstButtonText}
          </Button>
        )}
      </Col>
    </Row>
  );
};
SubtitleWithButton.propTypes = {
  text: PropTypes.string.isRequired,
  isFirstButtonHidden: PropTypes.bool,
  isSecondButtonHidden: PropTypes.bool,
  firstButtonText: PropTypes.string,
  secondButtonText: PropTypes.string,
  firstButtonDisable: PropTypes.bool,
  secondButtonDisable: PropTypes.bool,
  isPrimary: PropTypes.bool,
  firstButtonOnClick: PropTypes.func,
  secondButtonOnClick: PropTypes.func
};

SubtitleWithButton.defaultProps = {
  text: '',
  isFirstButtonHidden: true,
  secondButtonText: '',
  isSecondButtonHidden: true,
  firstButtonText: '',
  firstButtonDisable: false,
  secondButtonDisable: false,
  isPrimary: false,
  firstButtonOnClick: () => {},
  secondButtonOnClick: () => {}
};

export default SubtitleWithButton;
