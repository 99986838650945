import React, { useContext, useEffect } from 'react';
import { BrowserRouter, Navigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import ElChichoRoutes from 'routes/elChichoRoutes';
import { CloseButton } from 'components/common/Toast';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-toastify/dist/ReactToastify.min.css';
import { AuthContext } from 'context/Context';
import { useApiGet } from 'hooks/useApi';
import { ItHasValue } from 'utils/ObjectUtils';

const AuthValidator = () => {
  const { logout, isAuthenticated } = useContext(AuthContext);

  const validateJWTTokenGet = useApiGet(`api/v1/session/init`);

  useEffect(() => {
    validateJWTTokenGet.dispatchGet();
  }, []);

  useEffect(() => {
    if (ItHasValue(validateJWTTokenGet.error)) {
      //const statusCode = validateJWTTokenGet.error.response.data.statusCode;
      logout();
    }
  }, [validateJWTTokenGet.error]);

  return (
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      {!isAuthenticated() && <Navigate replace to="/authentication/login" />}
      <ElChichoRoutes />
      <ToastContainer
        closeButton={CloseButton}
        icon={false}
        position={toast.POSITION.BOTTOM_RIGHT}
      />
    </BrowserRouter>
  );
};

export default AuthValidator;
