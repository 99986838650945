import React from 'react';
import PropTypes from 'prop-types';
import ClientAndVehiclesDataReadOnly from './ClientAndVehiclesDataReadOnly';
import TextDivider from 'components/el-chicho-jr/common/TextDivider';
import NewSaleDetailsPayment from './NewSaleDetailsPayment';

const NewSaleDetails = ({ saleData, setSaleData }) => {
  return (
    <>
      <TextDivider title={'Información general'} align={'center'} />
      <ClientAndVehiclesDataReadOnly saleData={saleData} />
      <TextDivider title={'Detalles'} align={'center'} />
      <NewSaleDetailsPayment saleData={saleData} setSaleData={setSaleData} />
    </>
  );
};
NewSaleDetails.propTypes = {
  saleData: PropTypes.object,
  setSaleData: PropTypes.func
};

NewSaleDetails.defaultProps = {};

export default NewSaleDetails;
