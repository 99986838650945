import React from 'react';
import { Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { formatCurrencyValue, getReadOnlyInputStyles } from './InputUtils';

const InputCurrency = ({
  id,
  name,
  label,
  value,
  error,
  onChange,
  placeholder,
  readOnly,
  col
}) => {
  const [inputValue, setInputValue] = React.useState(value);

  const handleInputChange = event => {
    let value = event.target.value;
    const onlyNumbersAndTwoDecimals = value.replace(/[^\d.]+|(?<=\..*)\./g, '');

    const parts = onlyNumbersAndTwoDecimals.split('.');
    if (parts.length > 1 && parts[1].length > 2) {
      value = `${parts[0]}.${parts[1].slice(0, 2)}`;
    } else {
      value = onlyNumbersAndTwoDecimals;
    }

    if (parts[0].length > 7) {
      value = `${parts[0].slice(0, 7)}.${parts[1] || '00'}`;
    }

    setInputValue(value);
  };

  const handleBlur = () => {
    onChange?.(parseFloat(inputValue));
  };

  return (
    <Form.Group className="mb-3" as={col} controlId={id}>
      <Form.Label>{label ?? ''}</Form.Label>
      <Form.Control
        type={'text'}
        name={name}
        placeholder={placeholder}
        isInvalid={error}
        value={formatCurrencyValue(inputValue)}
        readOnly={readOnly}
        onChange={handleInputChange}
        onBlur={handleBlur}
        style={getReadOnlyInputStyles(readOnly)}
      />
      <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
    </Form.Group>
  );
};
InputCurrency.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  error: PropTypes.string,
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
  col: PropTypes.any
};

InputCurrency.defaultProps = {
  id: '',
  name: '',
  label: '',
  value: '',
  readOnly: false,
  error: null,
  onChange: () => {}
};
export default InputCurrency;
