import BaseTable from 'components/el-chicho-jr/common/table/BaseTable';
import React from 'react';
import PropTypes from 'prop-types';

const AddClientModalContent = ({
  onSelectedClient,
  allClientsForNewSaleData,
  isLoading
}) => {
  return (
    <BaseTable
      columns={allClientsForNewSaleData?.columns ?? []}
      data={allClientsForNewSaleData?.data ?? []}
      searchBarWidth={'40%'}
      onRowClick={item => onSelectedClient?.(item)}
      isLoading={isLoading}
    />
  );
};
AddClientModalContent.propTypes = {
  onSelectedClient: PropTypes.func,
  allClientsForNewSaleData: PropTypes.object,
  isLoading: PropTypes.bool
};

AddClientModalContent.defaultProps = {};
export default AddClientModalContent;
